import React from "react";
import doc from "../../assets/images/document.png";
import { formatDate } from "../../utils/utils";
import { Modal } from "antd";
import _ from "lodash";

import pdf from "../../assets/images/icons/pdf.png";
import image from "../../assets/images/icons/image.png";
import docx from "../../assets/images/icons/docx.png";
import json from "../../assets/images/icons/json.png";
import zip from "../../assets/images/icons/zip.png";

function Document(props) {
  const document = props.document;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const fileTypes = {
    pdf: pdf,
    png: image,
    jpg: image,
    jpeg: image,
    docx: docx,
    doc: docx,
    json: json,
    zip: zip,
  };

  return (
    <>
      <div
        onClick={showModal}
        className="p-4 duration-300 bg-white rounded-md cursor-pointer group hover:bg-orange-400"
      >
        <img src={doc} alt="document" />
        <p className="font-medium">
          {document.cargo}{" "}
          <span className="text-sm text-gray-600">
            [{document.documents.length} File(s)]
          </span>
        </p>
        <p className="text-sm text-gray-600">
          Posted on: {formatDate(document.creation_datetime)}
        </p>
      </div>
      <Modal
        title={document.cargo}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        {_.map(document.documents, (document, i) => {
          const extension = _.toLower(_.last(document.split(".")));
          const name = document.slice(34).split("--")[1];
          return (
            <div className="mt-2" key={i}>
              <a href={document} target="_blank" rel="noreferrer" download>
                <div className="flex items-center gap-2">
                  <img
                    className="w-6"
                    src={
                      fileTypes[extension] === undefined
                        ? pdf
                        : fileTypes[extension]
                    }
                    alt="icon"
                  />
                  <p>{name}</p>
                </div>
              </a>
            </div>
          );
        })}
      </Modal>
    </>
  );
}

export default Document;
