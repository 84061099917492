import React from "react";
import HeaderBanner from "../../components/header-banner";
import broker from "../../assets/images/banner/broker.jpg";

function CargoOwner() {
  return (
    <div>
      <HeaderBanner
        image={broker}
        title="Cargo Owner"
        description="Automate the brokering process."
      />

      <div>
        <p className="pt-10 text-2xl font-semibold text-center pb-60">
          Coming soon
        </p>
      </div>
    </div>
  );
}

export default CargoOwner;
