import React from "react";
import Home from "../../pages/landing/home";
import { Routes, Route } from "react-router-dom";
import Sustainability from "../../pages/landing/sustainability";
import PrivacyPolicy from "../../pages/documents/privacy-policy";
import TermsAndConditions from "../../pages/documents/terms-and-conditions";
import Brokers from "../../pages/landing/brokers";
import CargoOwner from "../../pages/cargo-owner";
import RudiApp from "../../pages/landing/app";
import AboutUs from "../../pages/landing/about";
import ContactUs from "../../pages/landing/contact";
import Blog from "../../pages/landing/blog";
import BlogDetail from "../../pages/landing/blog/blog-detail";

function Content() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="sustainability" element={<Sustainability />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="brokers" element={<Brokers />} />
        <Route path="cargo-owner" element={<CargoOwner />} />
        <Route path="rudi-app" element={<RudiApp />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<BlogDetail />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default Content;
