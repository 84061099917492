import React, { useState, useEffect } from "react";

import { Carousel, IconButton } from "@material-tailwind/react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import _ from "lodash";

import blue_nile from "../../../assets/images/partners/blue_nile_logo.png";
import bulk from "../../../assets/images/partners/bulk_logo.png";
import kamaka from "../../../assets/images/partners/kamaka_logo.png";
import shantui from "../../../assets/images/partners/shantui_logo.png";
import sita_steel from "../../../assets/images/partners/sita_steel_logo.png";
import smile_plast from "../../../assets/images/partners/smile_plast_logo.png";
import jk_cement from "../../../assets/images/partners/jk-cement.png";
import sinohydro from "../../../assets/images/partners/sinohydro.png";
import stecol from "../../../assets/images/partners/stecol.png";
import match_master from "../../../assets/images/partners/match_master.png";
import bamburi from "../../../assets/images/partners/Bamburi_Cement_Logo.png";

import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

function Testimonial() {
  const quotes = [
    {
      quote:
        "Rudi has been a reliable partner for us. Their tech innovation has brought efficiency to our logistics. They've made our exports hassle-free. We look forward to a long standing partnership",
      image: blue_nile,
    },
  ];

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const companies = [
    jk_cement,
    blue_nile,
    bulk,
    kamaka,
    shantui,
    sita_steel,
    smile_plast,
    sinohydro,
    stecol,
    match_master,
    bamburi,
  ];

  return (
    <div className="pt-12 bg-gray-100">
      <div className="container mx-auto">
        <Carousel
          autoplay
          loop
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 -translate-y-2/4 left-4"
            >
              <BsArrowLeft strokeWidth={2} className="w-6 h-6 text-gray-400" />
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 -translate-y-2/4 !right-4"
            >
              <BsArrowRight strokeWidth={2} className="w-6 h-6 text-gray-400" />
            </IconButton>
          )}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute flex gap-2 z-6 bottom-4 left-2/4 -translate-x-2/4">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? "bg-black w-8" : "bg-gray-500 w-4"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {_.map(quotes, (quote, i) => (
            <div key={i} className="w-full">
              <div>
                <div className="flex justify-center"></div>
                <h2 className="px-8 mt-6 text-xl font-medium text-center sm:text-2xl xl:px-70 lg:px-60 md:px-20">
                  {quote.quote}
                </h2>
              </div>

              <div className="flex justify-center gap-3 mt-8 mb-12 item-center">
                <img src={quote.image} alt="profile" className="h-12" />
              </div>
            </div>
          ))}
        </Carousel>

        {/* <div className="grid grid-cols-2 px-4 py-10 mt-4 bg-white gap-y-10 gap-x-8 lg:grid-cols-5 md:grid-cols-3 lg:px-10">
          {_.map(companies, (company, i) => (
            <div className="flex justify-center" key={i}>
              <img
                src={company}
                alt="logos"
                className="object-contain w-full max-h-20"
              />
            </div>
          ))}
        </div> */}
      </div>

      <div className="px-4 py-10 mt-4 bg-white">
        <div className="container mx-auto">
          <p className="text-2xl font-bold text-center rudi-color sm:text-3xl">
            Our Clients
          </p>
          <Swiper
            spaceBetween={30}
            modules={[Autoplay, Pagination, Navigation, A11y]}
            slidesPerView={
              windowSize[0] < 1400
                ? windowSize[0] < 1200
                  ? windowSize[0] < 1000
                    ? windowSize[0] < 800
                      ? 3
                      : 4
                    : 5
                  : 5
                : 6
            }
            navigation
            pagination={{ clickable: true, dynamicBullets: true }}
            autoplay={true}
            className="mt-10"
          >
            {_.map(companies, (company, k) => (
              <SwiperSlide key={k}>
                <div className="flex items-center justify-center">
                  <img
                    src={company}
                    alt="logos"
                    className="object-contain max-h-20"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
