/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import _ from "lodash";
import postData from "../../../hooks/useFetch";
import truck_blue from "../../../assets/images/truck_blue.png";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { formatDateTime } from "../../../utils/utils";

const containerStyle = {
  width: "100%",
  height: "92vh",
};

const center = {
  lat: -1.286389,
  lng: 36.817223,
};

function Tracking() {
  document.title = "Cargo Tracking";
  const [trucks, setTrucks] = React.useState([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0dy46oTvw9PivnuoTzy_aa5LDp_8FNIo",
  });

  const [info, setInfo] = React.useState({});

  let location = useLocation();

  React.useEffect(() => {
    console.log(info);
  }, [info]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      let merchant_id = location.pathname.substring(1);
      merchant_id = merchant_id.length > 1 ? merchant_id.split("/")[1] : "";

      if (merchant_id === undefined) message.error("Unknown user");
      else {
        postData({
          service: "cargo_tracking",
          data: {
            merchant_id: merchant_id,
          },
        }).then((data) => {
          if (data.success !== undefined && data.success === 1) {
            setTrucks(data.data);
          } else {
            message.error("Request failed");
            setTrucks([]);
          }
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p className="p-4">Cargo tracking</p>

      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
          {_.map(trucks, (t) => (
            <>
              <MarkerF
                icon={{
                  url: truck_blue,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{
                  lat: t.latitude,
                  lng: t.longitude,
                }}
                onClick={() => setInfo(t)}
              />
              {Object.keys(info).length > 0 && info.truck_id === t.truck_id && (
                <InfoWindowF
                  position={{
                    lat: t.latitude,
                    lng: t.longitude,
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -25),
                  }}
                  onCloseClick={() => setInfo({})}
                >
                  <div className="marker_pop_up">
                    <h6 className="font-medium">{info.truck_no}</h6>
                    <p className="font-medium">Driver : {info.driver_name}</p>
                    <p className="mt-2">{info.type}</p>

                    <p>
                      Tel: <a href={`tel:+${info.phone}`}>+{info.phone}</a>
                    </p>
                    <p className="mt-3 font-semibold text-blue-900">
                      Last updated: {formatDateTime(info.modification_datetime)}
                    </p>
                  </div>
                </InfoWindowF>
              )}
            </>
          ))}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Tracking;
