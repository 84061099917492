import React from "react";
import HeaderBanner from "../../components/header-banner";
import terms_conditions from "../../assets/images/banner/terms_conditions.jpg";
import { Helmet } from "react-helmet";

function TermsAndConditions() {
  const page = {
    title: "Terms and conditions",
    description:
      "Contractual rights and obligations of a party to any contract",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={terms_conditions}
        title="General Terms of Use and Contracting"
        description="Contractual rights and obligations of a party to any contract"
      />

      <div className="container px-5 pt-8 pb-10 mx-auto">
        <p>
          These general terms of use and legal information (hereinafter, the
          "General Terms") apply to the website, mobile apps, email newsletters
          and subscriptions and other digital properties (all together referred
          to as the 'Online Platforms' or 'Platforms') of Rudi Corporation
          Limited hereinafter, "The Company" The Platform belongs to The
          Company. By using the Platform, you accept and expressly agree to
          these terms of use. If you do not agree, please refrain from using it.
          The Company hereby makes the The Company's Platform available to users
          (hereinafter, the "User" or "Users"). In accordance with the
          provisions of the applicable legislation, the following identification
          details of the Platform owner are provided below:
        </p>

        <ul className="mt-2 list-disc ms-4">
          <li>Company name: Rudi Corporation Ltd. </li>
          <li>Registered address: P.O Box 0202, NYAHURURU</li>
          <li>Commercial Register registration details: SAJA RIDGES, KIAMBU</li>
        </ul>

        <ol className="document-list">
          <li>
            <h2>Object</h2>
            <p>
              The Company is a technology company whose main activity is the
              development and management of a technology platform through which
              certain local truck owners in a number of territories can offer
              their products and/or services by means of a mobile or web
              application on the platform and, in an ancillary manner, where
              applicable and if so requested through the Platform by users of
              the Platform and consumers of the said local truck owners, act as
              an intermediary in the planned or immediate delivery of the
              products.
            </p>
            <p>
              The Company has developed a Platform through which a variety of
              vendors, with which The Company can have in place a commercial
              agreement for the use of the platform, offer a selection of
              products and/or services. Users can request the purchase and/or
              collection of products and services from such vendors in person by
              means of a mandate given by them to a third party when placing an
              order through the Platform. In such cases, The Company acts as a
              mere intermediary and is therefore unable to assume, and does not
              assume, any responsibility for the quality of the products or for
              the proper provision of the services offered directly by the
              vendors or by such third parties.
            </p>

            <p>
              In addition, The Company is a multi-category Technology Platform
              for intermediation in the contracting of "on demand" services by
              distance electronic means. Its aim is to make it easier for any
              persons requiring help with their errands, services, purchases in
              person and/or collection of products from local vendors
              (hereinafter, "Users") to carry out their errands by delegating to
              such third parties, who are willing to voluntarily carry out the
              mandate given to them by Users (hereinafter, the "Vendors").
            </p>
            <p>
              Vendors are thus a network of self-employed professionals who work
              with The Company. When they wish to provide their services, they
              log onto the The Company Platform and undertake to carry out
              within a certain amount of time the service entrusted to them by
              the User by means of the above-mentioned mandate. As a result, The
              Company cannot be responsible for delivery times, as they will
              depend on the Vendor's own successful operations, the preparation
              of orders, the Vendors themselves, and the information furnished
              by Users when placing orders or their availability and response at
              the time of delivery. In relation to this, the delivery times
              stated on the Platform are provided for indicative purposes only.
            </p>
            <br />
            <p>
              The Company hereby confirms that it has obtained all necessary
              licences for the pursuit of its commercial activities in the areas
              in which it operates.
            </p>
          </li>
          <li>
            <h2>Terms of Use</h2>
            <p>
              By accessing the Platform and voluntarily creating a profile, all
              Users acknowledge and expressly and unambiguously agree to these
              General Terms of Use and Contracting as well as to the Privacy
              Policy and the Cookie Policy.
            </p>
          </li>

          <li>
            <h2>User Access and Registration</h2>
            <p>
              In order to be a User of the Platform, it is essential that you
              meet the following requirements:
            </p>
            <ul>
              <li>Users must be at least 18 years of age.</li>
              <li>
                Users must truthfully complete the mandatory fields of the
                registration form requesting personal details such as the User's
                name, e-mail address, telephone number.
              </li>
              <li>Users must agree to these Terms of Use and Contracting.</li>
              <li>
                Users must agree to the Privacy and Data Protection Policy.
              </li>
              <li>Users must agree to the Cookie Policy.</li>
            </ul>

            <p>
              The User warrants that all the information regarding his/her
              identity and capacity provided to The Company in the registration
              forms for the Platform is true, accurate and complete. In
              addition, Users undertake to keep their details up to date.
            </p>

            <p>
              If a User provides any false, inaccurate or incomplete information
              or if The Company considers that there are well founded reasons to
              doubt the truthfulness, accuracy or integrity of such information,
              The Company may deny that User current or future access to, and
              use of, the Platform or any of its contents and/or services.
            </p>
            <p>
              When registering on the Platform, Users must choose a username and
              password. Both the username and the password are strictly
              confidential, personal and non-transferable. In order to increase
              the security of accounts, The Company recommends that Users do not
              use the same login credentials as on other platforms. If a User
              uses the same login credentials as on other platforms, The Company
              will be unable to guarantee the security of the account or ensure
              that the User is the only person logging into his/her profile.
            </p>
            <p>
              Users undertake not to disclose the details of their account or
              allow access thereto to third parties. Users shall be solely
              responsible for any use of such details or of the services of the
              Platform that may be made by third parties, including the
              statements and/or contents entered on the Platform, or for any
              other action carried out under their username and/or password.
            </p>
            <p>
              Users are the sole owners of the content entered by them on the
              Platform. Furthermore, by registering on the Platform and agreeing
              to these Terms, the User grants The Company, in relation to the
              content that he/she may provide, a worldwide, irrevocable, and
              transferable licence, free of charge, with the right to
              sub-license, use, copy, modify, create derivative works,
              distribute, publicize and exploit it in any way that may be deemed
              appropriate by The Company, with or without any further
              communication to the User and without having to pay any amounts
              for such uses.
            </p>
            <p>
              The Company cannot guarantee the identity of registered Users, and
              it will therefore not be liable for the use by unregistered third
              parties of a registered User's identity. Users undertake to inform
              The Company immediately, using the communication channels made
              available by The Company, if their credentials are stolen,
              disclosed or lost.
            </p>
          </li>

          <li>
            <h2>Profile</h2>
            <p>
              In order to complete their registration on the Platform, Users
              must provide certain details, such as: username, e- mail address,
              telephone number, among others. Once they have completed the
              registration process, all Users will be able to access, complete
              and edit their profile as they deem appropriate. The Company does
              not store users' payment data, which shall be processed and stored
              by the payment service provider as described in these Terms and in
              the Privacy Policy.
            </p>
          </li>

          <li>
            <h2>Operation of the Service. Terms of the Mandate.</h2>
            <p>
              When a User who is registered as a User needs help with an errand,
              he/she must go to the online platform or the relevant The Company
              application and request the service using the said electronic
              methods. The basic courier service involves the collection and
              subsequent delivery of a product to and from the addresses
              established by the User, provided that they are within the
              territory in which The Company operates. Similarly, a User may ask
              a Vendor to purchase products in person on his/her behalf and to
              collect them from, and deliver them to, the addresses specified.
              The Vendor undertakes under a mandate to purchase the products
              ordered by the User on the latter’s behalf and according to
              his/her indications and specifications.
            </p>

            <p>
              Users are solely responsible for providing the correct addresses
              for delivery and collection on the Platform, and they therefore
              release The Company and the Vendor from any liability for
              negligence or error in the collection or delivery of the order as
              a result of providing incorrect addresses for collection and
              delivery. As a result of the foregoing, the cost resulting from
              providing an incorrect address for collection and delivery on the
              Platform shall be borne by the User.
            </p>
            <p>
              Users must provide all the information, in as much detail as
              possible, about the service to which the assignment relates and,
              if applicable, in relation to the product that they are asking the
              Vendor to buy in a physical shop on their behalf. To do this, they
              may provide any comments they may deem useful in the "comments"
              section as well as, where appropriate, share a photograph with the
              Vendor to identify the order. Users will be in constant contact
              with the Vendor and may communicate with him/her at any time to
              ensure that he/she carries out the mandate according to the Users’
              own instructions.
            </p>

            <p>
              In order to facilitate direct communication with the Vendor and in
              the event of any incidents in the processing of the order and/or
              in order to inform of any changes thereto, THE COMPANY makes
              available to Users an internal chat system allowing direct and
              immediate contact between the User and the Vendor while the order
              is being carried out. The chat session will be deactivated as soon
              as the User has received the product, or the order is cancelled
              for any of the reasons envisaged. If a User wishes to contact the
              Vendor or The Company after the order has been completed, he/she
              must use the contact form available on the Platform and contact
              the User assistance service.
            </p>

            <p>
              If the User is not at the place agreed for delivery, the Vendor
              shall retain the product for 24 hours, or for 5 minutes in the
              case of perishable products. In addition, the User must bear 100%
              of the cost of the basic logistics service, as well as the price
              of the product if one has been purchased or contracted through the
              Vendor at the User’s request and will have to pay for another
              service in order to receive the products that were not delivered.
              The Vendor will in no event be liable for the deterioration or
              expiry of the product to which the assignment related.
            </p>
          </li>

          <li>
            <h2>Prices of the Services and Billing</h2>
            <p>
              Users can register for the Platform, and use it, free of charge,
              although this is subject to review by The Company at any time.
              Users may have to pay for certain services on the Platform as
              provided in these General Terms and Conditions of Use.
            </p>
            <p>
              The use of the Platform by Vendors and Users may have an
              associated cost based on the country from which they wish to use
              the Platform to provide services.
            </p>
            <p>
              The User only has to pay for each service requested through the
              Platform for his/her use thereof to order products, and to
              communicate through the Platform, as well as for the courier or
              errand services provided by third parties.
            </p>
            <p>
              In addition, for services that include the purchase of a product,
              the User must pay the price of such product. By registering
              through the Platform and providing the required payment details,
              Users expressly authorize The Company to issue receipts for
              payment of the services requested, including the price and
              delivery of the products ordered.
            </p>
            <p>
              The total price of each service may be composed of a variable
              percentage based on the number of kilometers travelled and the
              time taken by the Vendor, as well as, where applicable in cases in
              which a User requests the physical purchase of a product or
              service, the price established by each vendor. The Company
              reserves the right to change the price based on the distance
              travelled and/or the time slot in which the service is performed.
              <b>
                In accordance with these terms, the User will be entitled to
                know the approximate fee for the service before contracting it
                and paying for it, unless the User has not specified the address
                for collection
              </b>
              . The fee for the delivery service may vary in cases of force
              majeure beyond The Company’s control that entail an increase in
              such fee.
            </p>
            <p>
              The fee may include tips for the Vendor and/or the local store,
              whose amount shall be solely and entirely at the User’s
              discretion.
            </p>
            <p>
              <b>
                The Company reserves the right to change the prices on the
                Platform at any time. Such changes shall take effect immediately
                after publication
              </b>
              . The User expressly authorizes The Company to send him/her by
              electronic means, to the e-mail address provided by the User
              during the registration process, receipts for the services
              contracted and/or the bills generated. If an invoice is required,
              the User must enter the relevant tax data on the platform before
              placing the order.
            </p>
            <p>
              If a service is cancelled by the User once the preparation of the
              order by the local vendor has been confirmed and the User has been
              informed, The Company will be entitled to charge the User the fee
              applicable in each case. Similarly, if the User has asked the
              Vendor to purchase a product on his/her behalf and the User
              cancels the order after the purchase has been made, the latter
              shall bear the cost of the delivery services provided by the
              Vendor as well as the full price of the product. All this without
              prejudice to the User’s ability to request a new service in order
              to return the products purchased or to have them delivered at a
              different address.
            </p>
          </li>

          <li>
            <h2>Right of Withdrawal and Cancellation of Orders</h2>
            <p>
              In accordance with the nature of the service offered by The
              Company, the User is aware that, once a Vendor has voluntarily
              accepted an order, the execution of the purchase mandate is
              considered to have started, and the User may therefore no longer
              be entitled to withdraw the service request free of charge.
            </p>
            <p>
              Without prejudice to the foregoing, the cost of cancellation may
              depend on the following factors;
            </p>
            <p>
              - If the User cancels the order once the Vendor has accepted it
              for processing, the User will be charged the cancellation fee. The
              User will be informed of the Vendor’s acceptance through the
              Platform. The User can see the cost of cancellation on the
              Platform. If the order is cancelled by The Company, the agents
              will contact the User to inform the latter of the cost of
              cancellation if there is one.
            </p>

            <p>
              The total cost of cancellation will appear on the User’s screen on
              pressing the "Cancel" button on the Platform. The User will be
              shown the charge to be applied, based on the factors described
              above, in advance.
            </p>
            <p>
              The Company reserves the right to cancel an order without having
              to provide a just cause. In the event of cancellation without just
              cause at The Company’s instance, the User shall be entitled to a
              refund of the amount paid.
            </p>
            <p>
              The Company has official claim forms available to Users, in
              relation to the service offered by it. Users may request the
              above-mentioned claim forms through The Company’s User assistance
              service, and the option to access them will be sent automatically.
              The User’s e-mail must specify the exact location from which the
              request is being made, which must be the same as the place where
              the service is to be carried out. In the event of doubt, the claim
              should be made from the latter place.
            </p>
          </li>
          <li>
            <h2>Geolocation</h2>
            <p>
              The Company may collect, use and share exact location data,
              including the real-time geographic location of the User’s computer
              or mobile device, provided that this is authorized by the User.
              The said location data may be collected and used by The company to
              show Users the location of the point of origin and/or destination
              of an assignment. In this regard, Users expressly consent to their
              geolocation data being shared with other Users and Vendors in
              order to successfully carry out the assignment requested at any
              given time. Users may choose to disable Geolocation on their
              devices.
            </p>
            <p>
              The User shall be responsible for entering the correct addresses
              for collection and delivery. In this regard, The Company will
              accept no liability for errors or omissions in the provision of
              such addresses by the User.
            </p>
          </li>

          <li>
            <h2>The User’s Obligations</h2>
            <p>
              Users are fully responsible for the proper use of, and access to,
              their profile and other Platform contents in accordance with the
              current legislation, be it national or international, of the
              Country from which they are using the Platform, as well as with
              the principles of good faith, morals, generally accepted customs
              and public order. Specifically, they undertake to diligently
              comply with these General Terms of Use.
            </p>
            <p>
              Users are responsible for correctly entering their individual
              usernames and passwords, which are non-transferable and must be
              sufficiently complex, and for not using the same username and
              password as on other platforms, all this in order to protect their
              account from fraudulent use by third parties not belonging to the
              Platform.
            </p>
            <p>
              Users shall refrain from using their profile and other Platform
              contents for illegal purposes or with illegal results that harm
              third-party rights and interests or that may in any way damage,
              disable, affect or impair the Platform and its contents and
              services. In addition, they are prohibited from hindering other
              Users’ normal use or enjoyment of the Platform.
            </p>
            <p>
              The Company may not be deemed to have editorial responsibility,
              and it expressly states that it does not identify with any
              opinions that may be issued by Users of the Platform, whose
              consequences shall be the sole responsibility of their issuers.
            </p>
            <p>
              Any persons who breach the above obligations shall be liable for
              any loss or damage caused by them. The Company will accept no
              liability for any consequences, loss or damage that may arise from
              such illegal use or access by third parties.
            </p>
            <p>
              In general, Users undertake, by way of example without limitation:
            </p>

            <ul>
              <li>
                To refrain from altering or modifying the Platform, in full or
                in part, by bypassing, disabling or in any other way tampering
                with, its functions or services;
              </li>
              <li>
                To refrain from infringing industrial and intellectual property
                rights or the personal data protection legislation;
              </li>
              <li>
                To refrain from using the Platform to insult, defame, intimidate
                or harass other Users or attack their image; - To refrain from
                accessing other Users’ e-mail accounts;
              </li>
              <li>
                To refrain from introducing computer viruses, corrupted files or
                any other software that may cause damage or alterations to The
                Company’s or third parties’ contents or systems;
              </li>
              <li>
                To refrain from sending mass and/or recurring e-mails to a
                number of people, or from sending third parties’ e-mail
                addresses without their consent;
              </li>
              <li>
                Any User may report another User if he/she believes that the
                latter is in breach of these General Terms of Use. Similarly,
                any User may inform The Company of any abuse or infringement of
                these terms through the Contact Form. The Company will check
                such reports as soon as possible and will take any steps that it
                may deem appropriate, reserving the right to remove and/or
                suspend any User from the Platform for breach of these General
                Terms of Use. Furthermore, The Company reserves the right to
                remove and/or suspend any message with illegal or offensive
                content without the need for a prior warning or subsequent
                notification.
              </li>
            </ul>
          </li>

          <li>
            <h2>De-registration by Users</h2>
            <p>
              Users may de-register from the Platform using the Contact Form on
              the Platform.
            </p>
          </li>

          <li>
            <h2>Rudi Corporation Limited’s Responsibility</h2>
            <p>
              Users are responsible for having in place the necessary services
              and equipment to browse the Internet and access the Platform.
              Users can report any incidents or problems accessing the Platform
              to The Company using the contact channels made available to Users,
              and The Company will analyze the incident and instruct the User on
              how to resolve it as quickly as possible.
            </p>
            <p>
              The Company does not control, and is not responsible for, the
              contents uploaded by Users through the Platform, and Users are
              solely responsible for the lawfulness of such contents.
            </p>
            <p>
              The Company will not be liable for any service interruptions,
              connection errors, unavailability of, or faults in, the Internet
              access service, or Internet interruptions or for any other matters
              beyond its control.
            </p>
            <p>
              The Company accepts no liability for any security errors that may
              arise or for any damage that may be caused to the User’s computer
              system (hardware and software), or to the files or documents
              stored therein, as a result of:
            </p>
            <ul>
              <li>
                The presence of a virus in the User’s computer system or mobile
                handset used to connect to the Platform’s contents and services;
              </li>
              <li>A malfunction of the browser;</li>
              <li>The use of outdated versions thereof.</li>
            </ul>
          </li>

          <li>
            <h2>Updates and Changes to the Platform</h2>
            <p>
              The Company reserves the right to amend these General Terms of
              Use, the Privacy Policy and the Cookie Policy at any time and
              without prior notice. Users must carefully read these General
              Terms when accessing the Platform. In any event, acceptance of the
              General Terms is an essential step to be taken before accessing
              the services and contents available through the The Company’s
              Platform.
            </p>
            <p>
              In addition, The Company reserves the right to update, amend or
              delete the information contained on its Platform, at any time and
              without the need for prior notice, with regard to its layout,
              presentation and access conditions, without thereby accepting any
              liability in relation thereto. The Company does not guarantee the
              absence of errors or interruptions when accessing the Platform or
              its content, or that it will always be up to date. Notwithstanding
              the foregoing, The Company shall, save in the event of
              circumstances making this difficult or impossible, take all steps
              to correct such errors, restore communication and update contents
              as soon as it becomes aware of the errors or disconnection or of
              the contents being out of date.
            </p>
          </li>
          <li>
            <h2>Intellectual Property</h2>
            <p>
              The company is the owner or licensee of all intellectual and
              industrial property rights included on the Platform as well as of
              the contents that can be accessed through it. The intellectual
              property rights of the Platform, as well as the text, images,
              graphic design, navigation structure, information and contents
              included therein, are the property of The Company, who has the
              exclusive right to exercise the exploitation rights therein in any
              manner, in particular the rights of reproduction, distribution,
              publication and transformation, in accordance with the Kenyan
              legislation on intellectual and industrial property rights.
            </p>
            <p>
              In spite of the foregoing, The Company may not be the owner or
              licence holder of content such as names or images, among others,
              of companies with which The Company does not have a business
              relationship. In such cases, The Company acquires the content from
              publicly available sources, and The Company shall in no event be
              deemed to be related to any right belonging to The Company.
            </p>
            <p>
              Authorizing a User to access the Platform does not imply the
              waiver, transfer, licensing or full or partial assignment by The
              Company of any intellectual or industrial property rights.
              Deleting, bypassing or in any way tampering with the contents of
              the The Company Platform are all prohibited. In addition,
              modifying, copying, reusing, exploiting, reproducing, publicizing,
              making second or subsequent publications of, uploading files,
              sending by post, transmitting, using, processing or distributing
              in any way all or some of the contents included in the The Company
              Platform for public or commercial purposes are also prohibited,
              save with The Company’s express written authorization or, where
              applicable, that of the owner of the rights concerned.
            </p>
            <p>
              Any User who shares any content of any kind through the Platform
              asserts that he/she has the necessary rights to do so, releasing
              The Company from any liability regarding the content and
              lawfulness of the information supplied. By providing content
              through the Platform, Users assign to The Company free of charge
              and to the maximum extent permitted by the current legislation,
              the exploitation rights on the intellectual or industrial property
              arising from such content.
            </p>
          </li>

          <li>
            <h2>Severability</h2>
            <p>
              If any of the clauses of these General Terms are found to be
              voidable or void by operation of law, such clause(s) shall be
              deemed not to have been included. Such declaration of nullity will
              not cause the other clauses to be void, and these shall remain
              valid and effective between the Parties.
            </p>
          </li>

          <li>
            <h2>Applicable Law</h2>
            <p>
              The relationship between The Company and the User shall be
              governed and construed in accordance with the General Terms, whose
              construction, validity and enforcement shall be governed by Kenyan
              laws.
            </p>
          </li>

          <li>
            <h2>Alternative Dispute Resolution</h2>
            <p>
              Any dispute, conflict, claim or controversy arising out of or
              broadly in connection with or relating to the Services or these
              Terms, including those relating to its validity, its construction
              or its enforceability (any "Dispute") shall be first mandatorily
              submitted to mediation proceedings.
            </p>
            <p>
              If such Dispute has not been settled within sixty (60) days after
              a request for mediation has been submitted under such ICC
              Mediation Rules, such Dispute can be referred to and shall be
              exclusively and finally resolved by arbitration.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsAndConditions;
