import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import {
  AiOutlineMenu,
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlineContacts,
} from "react-icons/ai";
import { BiLogIn } from "react-icons/bi";
import { LuFileSignature } from "react-icons/lu";
import { GiWorld } from "react-icons/gi";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa6";

import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";

function Header() {
  const icon_class = "text-lg";
  const [active, setActive] = useState("");
  const [scrolled, setScrolled] = useState(false);

  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  useEffect(() => {
    const onScroll = () => setScrolled(window.pageYOffset > 50);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const menus = [
    { label: "Home", link: "", icon: <AiOutlineHome className={icon_class} /> },
    {
      label: "About us",
      link: "about",
      icon: <AiOutlineInfoCircle className={icon_class} />,
    },
    // {
    //   label: "Cargo owner",
    //   link: "cargo-owner",
    //   icon: <BsMinecartLoaded className={icon_class} />,
    // },

    // { label: "Brokers", link: "brokers", icon: <SiIobroker /> },
    {
      label: "Sustainability",
      link: "sustainability",
      icon: <GiWorld className={icon_class} />,
    },
    {
      label: "Contact us",
      link: "contact",
      icon: <AiOutlineContacts className={icon_class} />,
    },
    {
      label: "Our blog",
      link: "blog",
      icon: <FaRegNewspaper className={icon_class} />,
    },

    {
      label: (
        <div className="flex items-center gap-2">
          <MdOutlinePhoneAndroid className="hidden text-2xl sm:block" />
          <p>Rudi App</p>
        </div>
      ),
      link: "rudi-app",
      icon: <MdOutlinePhoneAndroid className={icon_class} />,
    },
  ];

  const signs = [
    {
      label: "Sign up",
      link: "sign-up",
      icon: <LuFileSignature className={icon_class} />,
    },
    {
      label: "Sign in",
      link: "sign-in",
      icon: <BiLogIn className={icon_class} />,
    },
  ];

  let location = useLocation();
  useEffect(() => {
    setActive(location.pathname.substring(1));
    window.scrollTo({ behavior: "smooth", top: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div
        className={`fixed w-full z-20 transform transition duration-700 ${scrolled ? "bg-white shadow-md" : "border-b border-gray-700"
          }`}
      >
        {/* <div
        className={`fixed w-full z-20 transform transition duration-700 ${
          scrolled ? "bg-white shadow-md" : "backdrop-blur-sm"
        }`}
      ></div> */}
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <img src={logo} alt="logo" className="h-12" />
              <p
                className={`text-${scrolled ? "black" : "white"
                  } text-3xl font-bold`}
              >
                Rudi
              </p>
            </div>
            <div className="items-center hidden h-full gap-5 xl:flex">
              {_.map(menus, (menu, i) => (
                <div key={i} className="flex items-center h-full">
                  <Link
                    to={`/${menu.link}`}
                    className={`py-5 px-2 text-[15.5px] line-clamp-1 text-${scrolled ? "black" : "white"
                      } ${active === menu.link ? "active" : "inactive"
                      } hover:text-orange-700 transform transition-all duration-700`}
                  >
                    <p>{menu.label}</p>
                  </Link>
                </div>
              ))}
            </div>

            <Link
              to={"/sign-in"}
              className="py-[9px] text-[14px] font-medium text-white border-0 rudi-bg px-7 hover:shadow-md hover:shadow-gray-700 transform transition-all duration-500"
            >
              <p>Sign in / Sign up</p>
            </Link>
            {/* <Link
                to={"/sign-in"}
                className={` rounded-full px-8 py-[7px] border text-[14px] font-medium ${
                  scrolled
                    ? "border-black text-black"
                    : "border-white text-white"
                } hover:shadow-md hover:shadow-gray-500 transform transition-all duration-700`}
              >
                <p>Login</p>
              </Link> */}

            <div
              className={`xl:hidden border my-4 ${scrolled && "border-black"
                } p-2 rounded-md cursor-pointer`}
              onClick={openDrawer}
            >
              <AiOutlineMenu
                className={`text-xl text-${scrolled ? "black" : "white"}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`h-full ${open && "fixed z-20"} top-0 w-full`}>
        <Drawer open={open} onClose={closeDrawer} className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-1">
              <img src={logo} alt="logo" className="h-10" />
              <p className={`text-xl font-extrabold`}>Rudi</p>
            </div>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>
          <List>
            {_.map(menus, (menu, i) => (
              <Link to={`/${menu.link}`} onClick={closeDrawer} key={i}>
                <ListItem
                  className={`${active === menu.link && "bg-orange-700 text-white"
                    }`}
                >
                  <ListItemPrefix>{menu.icon}</ListItemPrefix>
                  <p className="">{menu.label}</p>
                </ListItem>
              </Link>
            ))}
            {_.map(signs, (sign, i) => (
              <Link to={`/${sign.link}`} onClick={closeDrawer} key={i}>
                <ListItem
                  className={`${active === sign.link && "bg-orange-700 text-white"
                    }`}
                >
                  <ListItemPrefix>{sign.icon}</ListItemPrefix>
                  <p className="">{sign.label}</p>
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      </div>
    </>
  );
}

export default Header;
