/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import logo from "../../../assets/images/logo_icon.png";

import { useSelector } from "react-redux";
import { user } from "../../../features";

import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import postData from "../../../hooks/useFetch";
import { message, Modal, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;
function VerifyEmail() {
  document.title = "Verify Email";
  const [otp, setOtp] = React.useState("");
  const [otpDis, setOtpDis] = React.useState(false);
  const navigate = useNavigate();

  const profile = useSelector(user.profile);

  const requestOTP = () => {
    postData({
      service: "request_otp",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        setTimeout(function () {
          window.location.reload(false);
        }, 2000);
      } else message.error(data.message);
    });
  };

  const verifyOTP = () => {
    postData({
      service: "verify_otp",
      data: {
        otp: otp,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        postData({
          service: "verified",
          data: {},
        }).then((data) => {
          if (data.success !== undefined && data.success === 1) {
            message.success(data.message);
            setTimeout(function () {
              window.location.reload(false);
            }, 2000);
          } else {
            setOtpDis(false);
            message.error(data.message);
          }
        });
      } else {
        setOtpDis(false);
        message.error(data.message);
      }
    });
  };

  const logout = () => {
    confirm({
      title: "Are you sure, you want to logout?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, you will be logged out.",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          sessionStorage.clear();
          navigate("/dashboard");
          window.location.reload(false);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  React.useEffect(() => {
    if (otp.toString().length === 6) {
      setOtpDis(true);
      verifyOTP();
    }
  }, [otp]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span className="text-red-500 cursor-pointer" onClick={requestOTP}>
          Request another one
        </span>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen main-bg">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-28" />
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-lg p-6 mx-4 mt-4 bg-white rounded-lg">
            <p className="mb-3 text-2xl font-semibold text-center">
              Email Verification
            </p>
            <p className="mx-8 text-center">
              Please use the One Time Password (OTP) that was sent to your email
              address <b>{profile.email_id}</b> to access the system
            </p>

            <div className="flex justify-center mt-5">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                renderSeparator={<span className="w-3"></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    disabled={otpDis}
                    className="text-xl border border-black rounded-md no-spinners"
                  />
                )}
              />
            </div>

            {otpDis && (
              <div className="flex items-center justify-center gap-4 mt-3">
                <Spin /> <p>Verifying, please wait...</p>
              </div>
            )}

            <p
              onClick={logout}
              className="mt-8 font-medium text-center text-red-800 cursor-pointer"
            >
              logout
            </p>

            <p className="mt-6">
              Haven't recieved the OTP code.{" "}
              <Countdown
                key={"sample"}
                date={Date.now() + 30 * 1000}
                renderer={renderer}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
