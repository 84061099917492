import React from "react";
import ghg from "../../../assets/images/ghg.png";

function Reporting() {
  return (
    <div className="bg-gray-200">
      <div className="container items-center block gap-8 px-6 py-10 mx-auto sm:flex sm:px-10">
        <img src={ghg} alt="ghg" className="h-16" />
        <p className="pt-5 text-lg sm:pt-0">
          We are also incorporating Integrated Reporting in the next year. RUDi
          will use the GRI standards for our report. Through GHG Accounting &
          Reporting Frameworks, Rudi will be able to communicate to customers,
          investors, and other key stakeholders its role in the reduction of GHG
        </p>
      </div>
    </div>
  );
}

export default Reporting;
