/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import _ from "lodash";
import postData from "../../hooks/useFetch";
import { Tooltip } from "antd";
import CreateTeam from "./create-team";
import InviteMember from "./invite-member";
import { formatDateTime } from "../../utils/utils";

import { useSelector, useDispatch } from "react-redux";
import { user, menu, menuAsync } from "../../features";

function MyTeam() {
  document.title = "My Team";
  const profile = useSelector(user.profile);
  const [data, setData] = React.useState([]);

  const reload = useSelector(menu.reload);
  const dispatch = useDispatch();

  const get_data = () => {
    postData({
      service: "team_members",
      data: {},
    }).then((data) => {
      dispatch(menuAsync.reload(false));
      if (data.success !== undefined && data.success === 1) setData(data.data);
    });
  };
  React.useEffect(() => {
    get_data();
  }, []);

  React.useEffect(() => {
    if (reload) {
      get_data();
    }
  }, [reload]);

  return (
    <div className="mt-6 max-w-[100%] overflow-hidden grid bg-white rounded-md px-4">
      <div className="grid items-center justify-between gap-4 p-3 sm:flex">
        <p className="font-semibold">Team Members</p>

        <div className="flex items-center gap-3">
          <CreateTeam />

          <InviteMember />

          <Tooltip title="Reload">
            <button
              className="flex items-center gap-3 px-4 py-2 bg-gray-200 rounded-md"
              onClick={get_data}
            >
              <AiOutlineReload />
            </button>{" "}
          </Tooltip>
        </div>
      </div>

      <div className="relative w-full mb-4">
        <table className="w-full">
          <thead>
            <tr style={{ backgroundColor: "rgb(240,240,240)" }}>
              <th>Email</th>
              <th>Role</th>
              <th>Department</th>
              {profile.team > 0 && <th>Status</th>}
              <th>Date sent</th>
            </tr>
          </thead>
          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>
                  <a href={`mailto:${d.email}`}>{d.email}</a>
                </td>
                <td>{d.role}</td>
                <td>{d.department}</td>
                {profile.team > 0 && (
                  <td>
                    {d.status === 0 && (
                      <span className="text-orange-500">Pending</span>
                    )}
                    {d.status === 1 && (
                      <span className="text-green-500">Accepted</span>
                    )}
                  </td>
                )}
                <td>{formatDateTime(d.creation_datetime)}</td>
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td colSpan={7} className="p-5 text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyTeam;
