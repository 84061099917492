import React from "react";

import { appName } from "../../../utils/constants";
import "react-slideshow-image/dist/styles.css";

// import banner from "../../../assets/images/tires.jpeg";
// import fleet from "../../../assets/images/banner/fleet.jpg";
// import truck from "../../../assets/images/banner/truck.jpg";

import bg1 from "../../../assets/images/bg/bg1.jpg";
import bg2 from "../../../assets/images/bg/bg2.jpg";
import bg3 from "../../../assets/images/bg/bg3.jpg";

import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { CiMap } from "react-icons/ci";

import { Link } from "react-router-dom";

import { A11y, Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

function Banner() {
  const slides = [
    {
      title: "Efficiency in Motion, Excellence in Delivery",
      text: `We as ${appName} pride ourselves on a streamlined operation that ensures your cargo reaches its destination on time, every time, while maintaining a standard of excellence in delivery service`,
      image: bg1,
      // image: banner,
      button: "Get Quote",
    },
    {
      title: "From Point A to B, We Deliver Beyond Expectation",
      text: `With a focus on precision and reliability, we guarantee seamless transport solutions that surpass your expectations, making us your trusted partner in every journey`,
      image: bg2,
      // image: fleet,
      button: "Get Quote",
    },
    {
      title: "On Time, Every Time. Your Trusted Transport Partner.",
      text: "Our unwavering commitment to punctuality and reliability. We prioritize delivering your goods promptly and consistently, earning your trust as a dependable transport partner.",
      image: bg3, // image: truck,
      button: "Get Started",
    },
  ];

  const icon_class = "text-5xl min-w-16 w-16 rudi-color";

  const services = [
    {
      icon: <IoPricetagsOutline className={icon_class} />,
      title: "Transparent Best Pricing",
      description:
        "Our commitment to transparency ensures that you always know exactly what you're paying for, without any guesswork",
    },
    {
      icon: <CiMap className={icon_class} />,
      title: "The Real Time Tracking",
      description:
        "With our cutting-edge technology, you can monitor progress, assets, and deliveries with pinpoint accuracy, right at your fingertips",
    },
    {
      icon: <MdOutlineSupportAgent className={icon_class} />,
      title: "24/7 Online Support",
      description:
        "Say goodbye to waiting anxiously for assistance! With our 24-hour support service, help is just a call away, anytime, day or night.",
    },
  ];
  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        modules={[Autoplay, A11y, EffectFade]}
        // navigation
        // pagination={{ clickable: true, dynamicBullets: true }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <div className="relative  min-h-[40rem] flex items-center">
              <div
                className={`absolute w-full h-full bg-center bg-no-repeat bg-cover brightness-[35%]`}
                style={{
                  backgroundImage: `url(${slide.image})`,
                }}
              />
              <div className={`container relative flex mx-auto mt-8 md:mt-16`}>
                <div className="px-6 py-10 md:px-12 md:max-w-xl bg-black/45 animate__animated animate__fadeIn">
                  <h1 className="text-3xl font-bold text-white xl:text-4xl">
                    {slide.title}
                  </h1>
                  <p className="mt-10 text-lg font-light text-white md:text-xl">
                    {slide.text}
                  </p>
                  <div className={`flex`}>
                    <Link to="/sign-in">
                      <p className="px-16 py-3 mt-8 text-sm font-medium text-white rudi-bg">
                        Get started
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="rudi-bg">
        <div className="container grid grid-cols-1 px-4 py-10 mx-auto divide-x divide-y divide-gray-400 lg:grid-cols-3">
          {services.map((s, i) => (
            <div key={i} className="flex gap-4 px-4 py-6 bg-white">
              {s.icon}
              <div>
                <p className="font-medium">{s.title}</p>
                <p className="text-xs md:text-sm">{s.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner;
