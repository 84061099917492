import React from "react";
import HeaderBanner from "../../../components/header-banner.js";
import Mission from "./mission";
import ImageAsideRight from "../../../components/image-aside-right.js";
import ImageAsideLeft from "../../../components/image-aside-left.js";
import data from "../../../assets/images/description/data.jpg";
// import custom_soln from "../../../assets/images/custom-solutions.jpg";
import cost_effective from "../../../assets/images/Cost-Efficiency.jpg";
import experience from "../../../assets/images/experience.png";
import truck_slice from "../../../assets/images/truck_slice.jpg";
import Team from "./team.js";
import about from "../../../assets/images/banner/about-us.jpg";
import { Helmet } from "react-helmet";

function AboutUs() {
  const page = {
    title: "About us",
    description:
      "Born out of necessity, Rudi is on a mission to serve shippers, truck owners and drivers by harnessing cutting-edge technology to forge seamless connections between cargo owners, transporters, and other stakeholders.",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>

      <HeaderBanner
        image={about}
        title="Moving Africa's Cargo"
        description="Welcome to Rudi, a trailblazer in logistics tailored for Africa. "
      />

      <p className="max-w-4xl px-6 py-10 mx-auto text-xl leading-8 tracking-wide">
        Born out of necessity, Rudi is on a mission to serve shippers, truck
        owners and drivers by harnessing cutting-edge technology to forge
        seamless connections between cargo owners, transporters, and other
        stakeholders.
      </p>

      <Mission />

      <ImageAsideLeft
        image={experience}
        title="Seamless Experience (Hassle- free Logistics)"
        description="We offer a seamless experience through our platform connecting both truck owners and cargo owners. We match cargo with reliable trucks, offer fast payments to truck owners and create transparency in logistics. We specialize in cross-border and incountry trips across industries including grains, cement, tiles, steel, FMCGs.
"
      />

      {/* <br />
      <ImageAsideRight
        image={custom_soln}
        title="Custom Solutions"
        description="We tailor our service to fit your particular needs ensuring reliability, efficiency across your deliveries so that you can focus on your core business.
"
      /> */}

      <div className="h-8" />
      <ImageAsideRight
        image={data}
        title="Data-driven solutions"
        description={`At our core, we believe in the power of data-driven solutions to optimize transportation and reduce costs. We use advanced algorithms to analyze routes, cargo weights, and other key factors to create the most efficient transportation plans possible. \nWe are dedicated to providing reliable and cost-effective logistics solutions that enable our clients to grow their businesses with ease.`}
      />

      <div className="h-16" />

      <div className="block mb-10 sm:flex">
        <div className="w-full">
          <div
            className="min-h-full bg-center bg-no-repeat bg-contain h-60"
            style={{ backgroundImage: `url(${cost_effective})` }}
          />
        </div>
        <div className="flex w-full px-10 py-10 md:py-16 md:ps-12">
          <div className="max-w-lg">
            <p className="mt-3 text-2xl font-bold rudi-color sm:text-3xl">
              Cost-Effective
            </p>
            <p className="mt-3 whitespace-pre-line sm:text-lg">{`We bring forth a game-changing opportunity to reduce your transport costs by 12-15%, enabling you to save money on your trips. \nOur commitment is to optimize your logistics expenses, ensuring efficiency and cost-effectiveness in every shipment.`}</p>
          </div>
        </div>
      </div>

      <div className="h-16" />

      <ImageAsideRight
        image={truck_slice}
        title="Innovative in tranportation industry"
        description="We are excited to be at the forefront of innovation in the transportation industry, and we are committed to creating a more sustainable future for our customers, our drivers, and the planet. Join us in our mission to revolutionize cargo transportation in Africa."
      />

      <Team />

      
    </div>
  );
}

export default AboutUs;
