import React, { useState } from "react";
import { FcInvite } from "react-icons/fc";
import { Modal, Input, message, Select } from "antd";
import postData from "../../hooks/useFetch";

import { useSelector, useDispatch } from "react-redux";
import { user, menuAsync } from "../../features";
import _ from "lodash";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";

function InviteMember() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = useSelector(user.profile);
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState([]);

  const [member, setMember] = useState({
    department: null,
    role_id: null,
    email: "",
  });

  const showModal = () => {
    setMember({
      department: null,
      role_id: null,
      email: "",
    });
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    postData({
      service: "departments",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        setDepartments(data.data);
    });
  }, []);

  const onSubmit = () => {
    postData({
      service: "invite_member",
      data: {
        role_id: member.role_id,
        email: member.email,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        dispatch(menuAsync.reload(true));
        message.success(data.message);
        setIsModalOpen(false);
      } else message.error(data.message);
    });
  };

  return (
    <div>
      {profile.team !== null && (
        <button
          onClick={showModal}
          className="flex items-center gap-3 px-6 py-2 text-white rounded-md dark-blue-bg"
        >
          <FcInvite /> <p className="text-sm">Invite Member</p>
        </button>
      )}

      <Modal
        title="Invite Member"
        open={isModalOpen}
        onOk={onSubmit}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <p className="mb-2">Department</p>
        <div className="flex gap-3">
          <Select
            style={{
              width: "100%",
            }}
            required
            placeholder="Select department"
            onChange={(e) => {
              member.department = parseInt(e);
              setMember({ ...member });
            }}
            options={departments.map((d) => {
              return { value: d.id.toString(), label: d.department };
            })}
          />
          <Link
            to="/department"
            className="flex items-center px-3 text-white rounded-md success-bg"
          >
            <BiPlus />
          </Link>
        </div>

        {member.department !== null && (
          <div>
            <p className="mt-4 mb-2">Role</p>
            <div className="flex gap-3">
              <Select
                style={{
                  width: "100%",
                }}
                required
                placeholder="Select role"
                onChange={(e) => {
                  member.role_id = parseInt(e);
                  setMember({ ...member });
                }}
                options={_.filter(departments, {
                  id: member.department,
                })[0].roles.map((d) => {
                  return { value: d.id.toString(), label: d.role };
                })}
              />
              <Link
                to="/roles"
                className="flex items-center px-3 text-white rounded-md success-bg"
              >
                <BiPlus />
              </Link>
            </div>
          </div>
        )}

        <div>
          <p className="mt-4 mb-2">Email Address</p>
          <Input
            type="text"
            required
            placeholder="Enter email address"
            value={member.email}
            onChange={(e) => {
              member.email = e.target.value;
              setMember({ ...member });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default InviteMember;
