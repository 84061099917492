/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import _ from "lodash";
import postData from "../../hooks/useFetch";
import { Tooltip, Select } from "antd";
import CreateRole from "./create-role";
import RolePermission from "./role-permission";
import { formatDateTime } from "../../utils/utils";

import { useSelector, useDispatch } from "react-redux";
import { menu, menuAsync } from "../../features";

function Roles() {
  document.title = "Roles";
  const [data, setData] = React.useState([]);
  const [param, setParam] = React.useState({
    department_id: "",
  });
  const [departments, setDepartments] = React.useState([]);

  const reload = useSelector(menu.reload);
  const dispatch = useDispatch();

  React.useEffect(() => {
    postData({
      service: "departments",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        setDepartments(data.data);
    });
  }, []);

  const roles = () => {
    postData({
      service: "get_roles",
      data: param,
    }).then((data) => {
      dispatch(menuAsync.reload(false));
      if (data.success !== undefined && data.success === 1) setData(data.data);
    });
  };
  React.useEffect(() => {
    roles();
  }, [param]);

  React.useEffect(() => {
    if (reload) {
      roles();
    }
  }, [reload]);

  return (
    <div className="mt-6 max-w-[100%] overflow-hidden grid bg-white rounded-md px-4">
      <div className="grid items-center justify-between gap-4 p-3 sm:flex">
        <p className="font-semibold">Roles</p>

        <div className="flex items-center gap-3">
          {departments.length > 0 && <CreateRole departments={departments} />}

          <Tooltip title="Reload">
            <button
              className="flex items-center gap-3 px-4 py-2 bg-gray-200 rounded-md"
              onClick={() => {
                setParam({ ...param });
              }}
            >
              <AiOutlineReload />
            </button>{" "}
          </Tooltip>
        </div>
      </div>

      <div className="relative w-full mb-4">
        <p className="mb-2 text-base font-bold ps-3">Department</p>
        <Select
          style={{
            width: "100%",
          }}
          required
          placeholder="Select department"
          onChange={(e) => {
            param.department_id = e;
            setParam({ ...param });
          }}
          options={departments.map((d) => {
            return { value: d.id.toString(), label: d.department };
          })}
        />
        <table className="w-full mt-6">
          <thead>
            <tr style={{ backgroundColor: "rgb(240,240,240)" }}>
              <th>Department</th>
              <th>Role</th>
              <th>Users</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{d.department}</td>
                <td>{d.role}</td>
                <td>{d.users}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
                <td>
                  <RolePermission role={d} />
                </td>
              </tr>
            ))}

            {data.length === 0 && (
              <tr>
                <td colSpan={6} className="p-5 text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Roles;
