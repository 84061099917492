/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import _ from "lodash";
import postData from "../../hooks/useFetch";
import { Tooltip } from "antd";
import CreateDepartment from "./create-department";
import { formatDateTime } from "../../utils/utils";

import { useSelector, useDispatch } from "react-redux";
import { menu, menuAsync } from "../../features";

function Department() {
  document.title = "Departments";
  const [data, setData] = React.useState([]);
  const reload = useSelector(menu.reload);
  const dispatch = useDispatch();

  const getData = () => {
    postData({
      service: "departments",
      data: {},
    }).then((data) => {
      dispatch(menuAsync.reload(false));
      if (data.success !== undefined && data.success === 1) setData(data.data);
    });
  };
  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);
  return (
    <div className="mt-6 max-w-[100%] overflow-hidden grid bg-white rounded-md px-4">
      <div className="grid items-center justify-between gap-4 p-3 sm:flex">
        <p className="font-semibold">Departments</p>

        <div className="flex items-center gap-3">
          <CreateDepartment />

          <Tooltip title="Reload">
            <button
              className="flex items-center gap-3 px-4 py-2 bg-gray-200 rounded-md"
              onClick={getData}
            >
              <AiOutlineReload />
            </button>{" "}
          </Tooltip>
        </div>
      </div>

      <div className="relative w-full mb-4">
        <table className="w-full">
          <thead>
            <tr style={{ backgroundColor: "rgb(240,240,240)" }}>
              <th>No</th>
              <th>Department</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{d.department}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
                <td>Action</td>
              </tr>
            ))}

            {data.length === 0 && (
              <tr>
                <td colSpan={3} className="p-5 text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Department;
