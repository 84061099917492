import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { user } from "../../features";
// import payment from "../../assets/images/icons/payment.png";
import invoice from "../../assets/images/icons/invoice.png";
import schedule from "../../assets/images/icons/schedule.png";
import documents from "../../assets/images/icons/documents.png";
// import reports from "../../assets/images/icons/reports.png";
import cargo from "../../assets/images/icons/cargo.png";

function Dashboard() {
  document.title = "Dashboard";
  const profile = useSelector(user.profile);

  const items = [
    { icon: cargo, label: "Post Cargo", link: "cargo", color: "#fad40026" },
    // { icon: payment, label: "Payments", color: "#32bdef23" },
    {
      icon: invoice,
      label: "Invoices",
      color: "#50e5ff42",
      link: "invoices",
    },
    {
      icon: schedule,
      label: "Schedule Pickup",
      link: "schedule",
      color: "#ffffff7f",
    },
    {
      icon: documents,
      label: "Documents",
      link: "documents",
      color: "#37cfee21",
    },
    // {
    //   icon: reports,
    //   label: "Reports",
    //   color: "#e3edf0",
    // },
  ];
  return (
    <div className="py-4">
      {Object.keys(profile).length > 0 && (
        <p className="pb-3 mb-3 font-medium border-b border-black dark-blue-color">
          Welcome back <b className="capitalize">{profile.name}</b> to your Logistics
          Dashboard!
        </p>
      )}
      <div className="grid grid-cols-1 gap-4 mt-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {_.map(items, (item, i) => (
          <Link key={i} to={`/${item.link}`}>
            <div
              className="flex justify-center p-5 transition-all duration-500 transform rounded-md shadow-md hover:shadow-lg"
              style={{ backgroundColor: `${item.color}` }}
            >
              <img src={item.icon} alt={item.label} className="h-16" />
            </div>
            <p className="mt-2 text-base font-medium text-center">
              {item.label}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
