import React from "react";

import { Modal, Button, Typography, Image } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import _ from "lodash";

import { formatDate, formatDateTime, numberFormatter } from "../../utils/utils";
import moment from "moment";

const { Text } = Typography;
function LoadDetails({ cargo }) {
  const [open, setOpen] = React.useState(false);

  /** Match Status */
  const match = () => {
    let ends = [];
    let starts = [];
    let complete = 0;
    cargo.match.map((m) => {
      if (m.end_trip !== null) ends.push(m);
      if (m.start_trip !== null) starts.push(m);
      return m;
    });

    if (starts.length === ends.length) complete = ends.length;

    if (cargo.loads === complete) return <Text type="success">Delivered</Text>;
    if (starts.length > 0) return <Text type="warning">ONroute</Text>;
    return <Text type="danger">Pending</Text>;
  };

  return (
    <>
      <Button
        type="primary"
        icon={<EyeOutlined />}
        onClick={() => {
          setOpen(true);
        }}
      />

      <Modal
        title={_.startCase(cargo.cargo)}
        open={open}
        width="1200px"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<Button onClick={() => setOpen(false)}>Close</Button>}
        centered
      >
        <div>
          <div className="grid gap-4 pt-4 md:grid-cols-2">
            <table>
              <tr>
                <td>Order no</td>
                <td>{cargo.id}</td>
              </tr>
              <tr>
                <td>Commodity</td>
                <td className="capitalize">{cargo.cargo}</td>
              </tr>
              <tr>
                <td>Requests</td>
                <td>{cargo.requests.length}</td>
              </tr>
              <tr>
                <td>Vehicle Type(s)</td>
                <td>
                  {_.map(cargo.type, (type, i) => (
                    <div key={i}>
                      <p>{type.type}</p>
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{match()}</td>
              </tr>
              <tr>
                <td>Number of trucks</td>
                <td>{numberFormatter(cargo.loads)}</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>
                  {numberFormatter(cargo.weight)} {cargo.weight_type}
                </td>
              </tr>
              <tr>
                <td>Git per load</td>
                <td>
                  {cargo.git_currency_alpha}.{" "}
                  {numberFormatter(cargo.git_per_load)}
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td>Loading Point</td>
                <td>{cargo.loading_point}</td>
              </tr>
              <tr>
                <td>Offloading Point</td>
                <td>{cargo.offloading_point}</td>
              </tr>
              <tr>
                <td>Loading Date</td>
                <td>{formatDate(cargo.loading_date)}</td>
              </tr>
              <tr>
                <td>Loading End</td>
                <td>{formatDate(cargo.loading_end)}</td>
              </tr>
              <tr>
                <td>Loading Time</td>
                <td>{moment(cargo.loading_time, "HH:mm").format("h:m A")}</td>
              </tr>

              <tr>
                <td>Rate</td>
                <td>{cargo.rate}</td>
              </tr>
              <tr>
                <td>Rate Amount</td>
                <td>
                  {cargo.rate_amount_currency_alpha}.{" "}
                  {numberFormatter(cargo.rate_amount)}
                </td>
              </tr>
              <tr>
                <td>Payment Term</td>
                <td>{cargo.term}</td>
              </tr>
              <tr>
                <td>Days</td>
                <td>{cargo.days}</td>
              </tr>
              <tr>
                <td>Posted by</td>
                <td>{cargo.name}</td>
              </tr>
              <tr>
                <td>Date Created</td>
                <td>{formatDate(cargo.creation_datetime)}</td>
              </tr>
            </table>
          </div>

          {cargo.match.length > 0 && (
            <div
              className={`mt-3 relative w-full mb-4 ${cargo.match.length > 0 && "overload-table"
                }`}
            >
              <p className="m-2 text-base font-medium">Matched Trucks</p>
              <table className="top-0 w-full h-full overflow-y-auto ">
                <thead>
                  <tr>
                    <td>Status</td>
                    <td>Transaction Id</td>
                    <td>Request Id</td>
                    <td>Truck No Plate</td>
                    <td>Trailer No Plate</td>
                    <td>Bid Amount</td>
                    <td>Trip Start</td>
                    <td>Trip End</td>
                    <td>Proof of delivery</td>
                    <td>Driver's name</td>
                    <td>Contact</td>
                  </tr>
                </thead>
                <tbody>
                  {cargo.match.map((match, i) => (
                    <tr key={i}>
                      <td>
                        {match.start_trip === null && (
                          <Text type="danger">Not started</Text>
                        )}

                        {match.start_trip !== null &&
                          match.end_trip === null && (
                            <Text type="warning">ONroute</Text>
                          )}

                        {match.end_trip !== null && (
                          <Text type="success">Completed</Text>
                        )}
                      </td>
                      <td>{match.transaction_id}</td>
                      <td>{match.request_id}</td>
                      <td>{match.truck_no}</td>
                      <td>{match.trailer_no}</td>
                      <td>
                        {match.alpha3} {numberFormatter(match.amount)}
                      </td>
                      <td>
                        {match.start_trip !== null &&
                          formatDateTime(match.start_trip)}
                      </td>
                      <td>
                        {match.end_trip !== null &&
                          formatDateTime(match.end_trip)}
                      </td>
                      <td>
                        {match.proof !== null && (
                          <Image
                            src={`http://api.ruditrip.com/images/${match.proof.split(",")[0]
                              }`}
                            alt="proof"
                            style={{ objectFit: "contain" }}
                            width={150}
                          />
                        )}
                      </td>
                      <td>{match.driver_name}</td>
                      <td>{match.phone}</td>
                    </tr>
                  ))}
                  {cargo.requests.length === 0 && (
                    <tr>
                      <td colSpan={11}>
                        <p className="p-4 text-center">No Data</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {cargo.requests.length > 0 && (
            <div
              className={`relative w-full mb-4 ${cargo.requests.length > 0 && "overload-table"
                }`}
            >
              <p className="m-2 text-base font-medium">Requests</p>
              <table className="top-0 w-full h-full overflow-y-auto ">
                <thead>
                  <tr>
                    <td>No</td>
                    <td>Vehicle Type</td>
                    <td>Truck No Plate</td>
                    <td>Trailer No Plate</td>
                    <td>Bid Amount</td>
                    <td>Status</td>
                    <td>Driver's name</td>
                    <td>Contact</td>
                    <td>Truck owner</td>
                    <td>Request on</td>
                  </tr>
                </thead>
                <tbody>
                  {cargo.requests.map((request, i) => (
                    <tr key={i}>
                      <td>{request.id}</td>
                      <td>{request.type}</td>
                      <td>{request.truck_no}</td>
                      <td>{request.trailer_no}</td>
                      <td>
                        {request.alpha3} {numberFormatter(request.amount)}
                      </td>
                      <td>
                        {request.status === 0 && (
                          <Text type="default">Pending</Text>
                        )}
                        {request.status === 1 && (
                          <Text type="success">Accepted</Text>
                        )}
                        {request.status === 2 && (
                          <Text type="danger">Declined</Text>
                        )}
                      </td>
                      <td>{request.driver_name}</td>
                      <td>{request.phone}</td>
                      <td>{request.name}</td>
                      <td>{formatDateTime(request.creation_datetime)}</td>
                    </tr>
                  ))}
                  {cargo.requests.length === 0 && (
                    <tr>
                      <td colSpan={11}>
                        <p className="p-4 text-center">No Data</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default LoadDetails;
