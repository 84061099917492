import React from "react";
import _ from "lodash";
import sdg13 from "../../../assets/images/sdgs/sdg13.png";
import sdg17 from "../../../assets/images/sdgs/sdg17.png";
import sdg8 from "../../../assets/images/sdgs/sdg8.png";
import sdg9 from "../../../assets/images/sdgs/sdg9.png";
import SdgItem from "../../../components/sdg-item";

function Sdgs() {
  const sdgs = [
    {
      image: sdg8,
      title: "sdg 8",
      description:
        "Decent work and economic growth: Everyone deserves access to decent work and fair wages. By increasing wages for truck owners and drivers, we empower them to provide for their families and contribute to the growth of their communities.",
      color: "#9b0036",
    },
    {
      image: sdg9,
      title: "sdg 9",
      description:
        "Industry, innovation, and infrastructure: We are constantly innovating to create a more efficient and sustainable transportation industry. Our platform is built to optimize routes and reduce empty miles, reducing the carbon footprint of each trip.",
      color: "#fff",
    },
    {
      image: sdg13,
      title: "sdg 13",
      description:
        "Climate action: Climate change is one of the most pressing issues of our time, and we are doing our part to reduce our carbon footprint. Our platform reduces the carbon emissions of each trip, and we are constantly exploring new ways to reduce our environmental impact.",
      color: "#367935",
    },
    {
      image: sdg17,
      title: "sdg 17",
      description:
        "Partnerships for the goals: We are fostering this by creating transparency and ease of doing business for both truck owners and cargo owners. By optimizing routes and reducing empty miles, the startup can help reduce carbon emissions and collaborate with key players in the industry to achieve common goals.",
      color: "#1d3369",
    },
  ];
  return (
    <div className="container py-16 mx-auto">
      <div className="flex justify-center">
        <div className="max-w-3xl px-6">
          <p className="text-2xl font-bold text-center rudi-color">
            See the latest on sustainability
          </p>
          <p className="mt-3 text-center sm:text-lg">
            Our sustainability efforts are aligned with the United Nations
            Sustainable Development Goals (SDGs) and we are proud to directly
            align to 4 of them:
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 px-3 mt-10 sm:px-0 lg:px-10 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 lg:gap-6 sm:gap-4 md:px-10">
        {_.map(sdgs, (sdg, i) => (
          <SdgItem sdg={sdg} key={i} />
        ))}
      </div>
    </div>
  );
}

export default Sdgs;
