import React from "react";
import Sdgs from "./sdgs";
import Reporting from "./reporting";
import HeaderBanner from "../../../components/header-banner.js";
import sustainability from "../../../assets/images/banner/sustainability.jpg";
import { Helmet } from "react-helmet";

function Sustainability() {
  const page = {
    title: "Sustainability",
    description:
      "Our sustainability efforts are aligned with the United Nations. Sustainable Development Goals (SDGs) and we are proud to directly align to 4 of them",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={sustainability}
        title="Ship with the leader in sustainable freight"
        description="Sustainability is at the heart of what we do at Rudi."
      />

      <div className="flex justify-center mt-14">
        <div className="max-w-5xl px-6">
          <p className="text-2xl font-bold rudi-color">
            Empty Miles eliminated
          </p>
          <p className="mt-3 sm:text-lg">
            According to studies, empty miles are a big contributor to carbon
            emissions by cargo haulage trucks. At Rudi, we eliminate empty miles
            by matching cargo to trucks on the return trip.
          </p>
        </div>
      </div>

      <Sdgs />

      <Reporting />
    </div>
  );
}

export default Sustainability;
