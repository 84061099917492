import React from "react";
import truck from "../../../assets/images/truck.jpg";

function TruckOwners() {
  return (
    <div className="block main-bg sm:flex">
      <div className="flex justify-center w-full px-10 py-12 md:py-24">
        <div className="max-w-lg text-gray-400 sm:text-lg">
          <p className="text-2xl font-bold text-white">For Truck Owners</p>
          <p className="mt-3 text-2xl text-orange-700 sm:text-3xl">
            Truckload capacity without compromise
          </p>
          {/* <p className="my-3 ">
            Rudi provides unmatched flexibility with uncompromising quality,
            reducing shipping costs and helping you achieve your sustainability
            goals.
          </p> */}
          <p  className="my-3">
            With reduced empty miles we are able to increase the truck owner
            income by 50% to 70%
          </p>
        </div>
      </div>
      <div className="w-full">
        <div
          className="bg-center bg-no-repeat bg-cover sm:h-full h-52"
          style={{ backgroundImage: `url(${truck})` }}
        />
      </div>
    </div>
  );
}

export default TruckOwners;
