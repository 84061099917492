import React from "react";

function HeaderBanner({ image, title, description }) {
  return (
    <div className="relative">
      <div
        className="brightness-[35%] bg-no-repeat bg-center bg-cover absolute w-full h-full"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="container relative flex justify-center pt-24 mx-auto py-14 animate__animated animate__bounceInLeft">
        <div className="max-w-xl px-8">
          <p className="mt-6 text-4xl font-bold text-center rudi-color">
            {title}
          </p>
          <p className="mt-3 text-center text-gray-300 sm:text-xl">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeaderBanner;
