import React from "react";
import HeaderBanner from "../../../components/header-banner";
import app from "../../../assets/images/mobile_app1.jpg";
import main from "../../../assets/images/app/main.png";
import google_play from "../../../assets/images/app/google_play.png";
import AppFeatures from "./app-features";
import { Helmet } from "react-helmet";
import ImageAsideRight from "../../../components/image-aside-right";
import truckOwner from "../../../assets/images/truck-owner-mobile.jpg";

function RudiApp() {
  const page = {
    title: "Rudi Mobile Application",
    description:
      "Unlock a world of convenience at your fingertips with Our Rudi mobile app",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={app}
        title="Rudi Mobile Application"
        description="Unlock a world of convenience at your fingertips!"
      />

      <div>
        <div className="block md:flex">
          <div className="flex items-center justify-end w-full p-6">
            <img src={main} alt="Rudi app" className="max-h-80" />
          </div>
          <div className="flex w-full px-10 py-16 md:ps-12">
            <div className="max-w-lg">
              <p className="mt-3 text-xl font-bold rudi-color md:text-3xl">
                Increase your income per mile, reduce carbon efficiency, truck
                income and expenses all in one app.
              </p>
              <p className="mt-4 whitespace-pre-line md:text-lg">
                Our mobile app seamlessly blends innovation with user-friendly
                design, offering an immersive and intuitive experience. From
                streamlined navigation to personalized features, it's your
                gateway to efficiency, and empowerment
              </p>

              <a
                href="https://play.google.com/store/apps/details?id=com.ruditrip&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={google_play}
                  alt="Rudi on google play store"
                  className="mt-6 max-h-14"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col max-w-6xl gap-4 px-6 pb-10 mx-auto text-xl">
          <p>
            Welcome to our logistics app designed to revolutionize the trucking
            industry! At our core, we understand the critical need for efficient
            transport solutions that not only streamline operations but also
            prioritize sustainability. Our platform empowers transporters by
            providing seamless access to a network of opportunities, effectively
            minimizing empty miles and maximizing cargo utilization.
          </p>

          <p>
            We meticulously vet our transport partners to ensure reliability,
            professionalism, and alignment with our values of innovation and
            sustainability
          </p>

          <p>
            Join us in reshaping the future of logistics, where every mile
            counts towards a more efficient and eco-friendly transportation
            ecosystem.
          </p>
        </div>
      </div>

      <div>
        <ImageAsideRight
          image={truckOwner}
          title={`Hassle-free Cargo: \nGet Cargo through our Application.`}
          description={`Tracking: View updated truck location at all times.\nMore Income, Less empty miles: \nEarn more by getting return cargo.\n\nJoin the network today!\n <a href="https://play.google.com/store/apps/details?id=com.ruditrip&hl=en&gl=US" target="_blank"><button>Join us</button></a>`}
        />
      </div>

      <AppFeatures />
    </div>
  );
}

export default RudiApp;
