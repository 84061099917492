import React from "react";
import { AiOutlinePhone, AiOutlineMenu } from "react-icons/ai";
import { BsArrowRight, BsChatQuote } from "react-icons/bs";

import { useSelector, useDispatch } from "react-redux";
import { menu, menuAsync } from "../../features";

function HeaderManage() {
  const menus = useSelector(menu.menu);
  const drawer = useSelector(menu.drawer);
  const dispatch = useDispatch();
  const icon_class = "text-2xl";

  const handleDrawer = (draw) => {
    dispatch(menuAsync.drawer(draw));
  };

  return (
    <div className="absolute top-0 flex justify-between w-full p-3 bg-white pe-4">
      <div className="flex items-center gap-3">
        <div
          className="p-1.5 cursor-pointer"
          onClick={() => handleDrawer(!drawer)}
        >
          {drawer ? (
            <BsArrowRight className="text-lg" />
          ) : (
            <AiOutlineMenu className="text-lg" />
          )}
        </div>
        <p className="text-base">{menus}</p>
      </div>
      <div className="flex gap-8">
        <AiOutlinePhone className={icon_class} />
        <BsChatQuote className={icon_class} />
      </div>
    </div>
  );
}

export default HeaderManage;
