/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import postData from "../../hooks/useFetch";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { menuAsync } from "../../features";
import Document from "./document";
import no_doc from "../../assets/images/no_documents.png";

function Documents() {
  document.title = "Documents";

  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();

  const getData = () => {
    postData({
      service: "documents",
      data: {},
    }).then((data) => {
      dispatch(menuAsync.reload(false));
      console.log(data.data);
      if (data.success !== undefined && data.success === 1) setData(data.data);
    });
  };
  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {data.length > 0 ? (
        <div className="grid gap-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
          {_.map(data, (document, d) => (
            <Document key={d} document={document} />
          ))}
        </div>
      ) : (
        <div>
          <img
            src={no_doc}
            alt="No documents"
            className="mx-auto mt-8 max-h-52"
          />
          <p className="m-6 text-lg text-center">No Documents available</p>
        </div>
      )}
    </div>
  );
}

export default Documents;
