import React from "react";
import logo from "../../../assets/images/logo_icon.png";
import SignLinks from "./sign-links";
import PhoneInput from "react-phone-input-2";
import { Input, Select, message, Spin } from "antd";
import { isValidEmail } from "../../../utils/utils";
import postData from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";

function SignUp() {
  document.title = "Sign up";
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [confPasswordVisible, setConfPasswordVisible] = React.useState(false);
  const [confPassword, setConfPassword] = React.useState("");

  // const saveToken = (userToken) => {
  //   sessionStorage.setItem("token", JSON.stringify(userToken));
  // localStorage.setItem("token", JSON.stringify(userToken));
  // };

  const [data, setData] = React.useState({
    user_type: 3,
    last_name: "",
    first_name: "",
    email_id: "",
    mobile_code: 0,
    gender: null,
    dob: "2023-01-02",
    phone_no: "",
    password: "",
  });

  const input_class =
    "w-full focus:outline-none rounded-md text-sm px-3 py-2 mt-2";

  const onSubmit = (e) => {
    e.preventDefault();
    if (data.first_name.trim().length < 3)
      message.error("Please enter first name");
    else if (data.last_name.trim().length < 3)
      message.error("Please enter last name");
    else if (data.email_id.trim().length < 3)
      message.error("Please enter Email Address");
    else if (!isValidEmail(data.email_id.trim()))
      message.error("Provide a valid Email Address");
    else if (data.phone_no.trim().length < 5)
      message.error("Please enter Phone number");
    else if (data.gender < 0) message.error("Please select gender");
    else if (data.password.trim().length < 3)
      message.error("Please enter a strong Password");
    else if (data.password.trim().length !== data.password.length)
      message.error("Please remove space in your password");
    else if (data.password.trim() !== confPassword)
      message.error("Passwords are not the same");
    else {
      setLoading(true);
      postData({
        service: "sign_up",
        data: {
          user_type: 3,
          name: data.first_name.trim() + " " + data.last_name.trim(),
          email_id: data.email_id.trim(),
          mobile_code: 0,
          gender: data.gender,
          dob: "2023-01-02",
          phone_no: data.phone_no,
          password: data.password,
        },
      }).then((data) => {
        if (data.success !== undefined && data.success === 1) {
          message.success(data.message);
          setTimeout(function () {
            navigate("/sign-in");
            window.location.reload(false);
          }, 2000);
        } else message.error(data.message);
        setLoading(false);
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen main-bg">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-24" />
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-lg pb-6 mx-4 bg-white rounded-lg">
            <SignLinks />

            <form onSubmit={onSubmit}>
              <div className="px-6">
                <div className="mt-3">
                  <p className="text-sm ps-1">Name</p>
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                    <Input
                      type="text"
                      required
                      placeholder="First name"
                      className={input_class}
                      value={data.first_name}
                      onChange={(e) => {
                        data.first_name = e.target.value;
                        setData({ ...data });
                      }}
                    />
                    <Input
                      type="text"
                      required
                      placeholder="Last name"
                      className={input_class}
                      value={data.last_name}
                      onChange={(e) => {
                        data.last_name = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <p className="text-sm ps-1">Email Address</p>
                  <Input
                    type="email"
                    required
                    value={data.email_id}
                    onChange={(e) => {
                      data.email_id = e.target.value;
                      setData({ ...data });
                    }}
                    placeholder="Enter Email Address"
                    className={input_class}
                  />
                </div>

                <div className="mt-4 contact">
                  <p className="text-sm ps-1">Phone</p>
                  <PhoneInput
                    countryCodeEditable={false}
                    country={"ke"}
                    enableSearch={true}
                    required={true}
                    value={data.phone_no}
                    onChange={(e) => {
                      data.phone_no = e;
                      setData({ ...data });
                    }}
                    className="mt-2"
                  />
                </div>

                <div className="mt-4">
                  <p className="text-sm ps-1">Gender</p>

                  <Select
                    style={{
                      width: "100%",
                    }}
                    required
                    className="mt-2"
                    placeholder="Select gender"
                    value={data.gender}
                    onChange={(e) => {
                      data.gender = e;
                      setData({ ...data });
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Male",
                      },
                      {
                        value: 2,
                        label: "Female",
                      },
                      {
                        value: 0,
                        label: "Not say",
                      },
                    ]}
                  />
                </div>

                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                  <div className="mt-4">
                    <p className="text-sm ps-1">Password</p>
                    <Input.Password
                      required
                      placeholder="Enter Password"
                      className={input_class}
                      value={data.password}
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                      onChange={(e) => {
                        data.password = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <p className="text-sm ps-1">Confirm Password</p>
                    <Input.Password
                      required
                      placeholder="Confirm Password"
                      className={input_class}
                      value={confPassword}
                      visibilityToggle={{
                        visible: confPasswordVisible,
                        onVisibleChange: setConfPasswordVisible,
                      }}
                      onChange={(e) => setConfPassword(e.target.value)}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full p-3 mt-6 text-sm font-semibold text-white rounded-md rudi-bg"
                >
                  {loading ? (
                    <>
                      <Spin /> Loading...
                    </>
                  ) : (
                    "Create Account"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
