import _ from "lodash";

const getToken = () => {
  const tokenString = sessionStorage.getItem("token");
  if (tokenString !== null) return tokenString.replace(/['"]+/g, "");
  return "";
};

async function postData(data = {}) {
  const url = require("../utils/constants").base_url + "api";

  // Default options are marked with *
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    if (resp.success === -1) {
      sessionStorage.clear();
      window.location.reload(false);
      return resp;
    } else return resp;
  } catch (e) {
    if (_.includes(e.message, "Forbidden")) return { success: -1 };
    // alert("Check you internet connection");
    return { success: 3 };
  }
  // parses JSON response into native JavaScript objects
}

export async function admin_post(data = {}) {
  const url = require("../utils/constants").base_url + "admin";

  // Default options are marked with *
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    return resp;
  } catch (e) {
    console.log(e);
    if (_.includes(e.message, "Forbidden")) return { success: -1 };
    // alert("Check you internet connection");
    return { success: 3 };
  }
}

export default postData;
