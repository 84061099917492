import React from "react";
import logo from "../assets/images/logo.png";
import _ from "lodash";
import {
  AiFillLinkedin,
  AiOutlineTwitter,
  AiFillPhone,
  AiOutlinePhone,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { Link } from "react-router-dom";

function FooterLanding() {
  const social_class = "text-white text-xl";
  const socials = [
    {
      icon: <AiFillLinkedin className={social_class} />,
      link: "https://www.linkedin.com/company/rudi-trip/",
    },
    {
      icon: <AiOutlineTwitter className={social_class} />,
      link: "https://twitter.com/",
    },
    {
      icon: <BiMailSend className={social_class} />,
      link: "mailto:info@Ruditrip.com",
    },
  ];

  const footer = [
    {
      heading: "Who we are",
      links: [
        { label: "Shippers", link: "sign-in" },
        { label: "Brokers", link: "brokers" },
        { label: "Sustainability", link: "sustainability" },
      ],
    },
    {
      heading: "About us",
      links: [
        { label: "Who we are", link: "about" },
        { label: "our team", link: "about" },
        { label: "Terms and conditions", link: "terms-and-conditions" },
        { label: "Privacy policy", link: "privacy-policy" },
      ],
    },
  ];

  return (
    <div className="main-bg">
      <div className="container grid grid-cols-1 gap-6 py-8 mx-auto md:pt-16 sm:grid-cols-2">
        <div className="ps-3">
          <div className="grid grid-cols-2">
            <img src={logo} alt="logo" className="w-20" />
            <div>
              <div className="flex gap-4 mt-6">
                {_.map(socials, (s, i) => (
                  <a
                    href={s.link}
                    key={i}
                    rel="noreferrer"
                    target="_blank"
                    className="border-white border rounded-full p-1.5"
                  >
                    {s.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="grid gap-6 mt-4 lg:grid-cols-2">
            <div>
              <h2 className="font-semibold text-white">Kenya Office</h2>
              <div className="mt-3 text-gray-200">
                <p>Rudi Corporation Ltd Kenya Office.</p>
                <p>Saja Ridges, Banana hill Complex. Unit 1</p>
                <p>Nairobi, Kenya</p>
              </div>

              <div className="flex gap-4 mt-6">
                <a
                  href={"tel:+254724281124"}
                  rel="noreferrer"
                  target="_blank"
                  className="border-white border rounded-full p-1.5"
                >
                  <AiOutlinePhone className={social_class} />
                </a>

                <a
                  href={"tel:+254727770622"}
                  rel="noreferrer"
                  target="_blank"
                  className="border-white border rounded-full p-1.5"
                >
                  <AiFillPhone className={social_class} />
                </a>
              </div>
            </div>
            <div>
              <h2 className="font-semibold text-white">Tanzania Office</h2>
              <div className="mt-3 text-gray-200">
                <p>Rudi Corporation Ltd Tanzania Office</p>
                <p>Plot no 67</p>
                <p>Olosiva village, Arusha District</p>
              </div>

              <div className="flex gap-4 mt-6">
                <a
                  href={"tel:+254724281124"}
                  rel="noreferrer"
                  target="_blank"
                  className="border-white border rounded-full p-1.5"
                >
                  <AiOutlinePhone className={social_class} />
                </a>

                <a
                  href={"tel:+255767674444"}
                  rel="noreferrer"
                  target="_blank"
                  className="border-white border rounded-full p-1.5"
                >
                  <AiFillPhone className={social_class} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 px-3 md:grid-cols-2 md:px-0">
          {_.map(footer, (f, i) => (
            <div key={i}>
              <p className="mb-3 text-lg font-bold text-gray-200 capitalize mt-7 md:mt-0">
                {f.heading}
              </p>
              {_.map(f.links, (l, i) => (
                <div key={i}>
                  <Link to={`/${l.link}`}>
                    <p className="mt-2 text-base text-gray-200 capitalize">
                      {l.label}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Copyright section */}
      <div className="border-t-[0.1px] border-gray-800">
        <p className="py-6 text-sm text-center text-gray-600 capitalize">
          Copyright &copy; {new Date().getFullYear()}. RUDI. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default FooterLanding;
