import React from "react";
import FooterLanding from "../footer-landing";
import BackToUp from "@uiw/react-back-to-top";
import Header from "./header";
import { BsArrowUp } from "react-icons/bs";
import Content from "./content";

function MainLanding() {
  return (
    <div>
      <BackToUp size={40} className="absolute bottom-0 z-20 left-6">
        <BsArrowUp className="text-xl" />
      </BackToUp>
      <Header />
      <Content />
      <FooterLanding />
    </div>
  );
}

export default MainLanding;
