import React from "react";
import { Markup } from "interweave";

function ImageAsideRight({ image, title, description }) {
  return (
    <div className="flex flex-col-reverse sm:flex-row side_menu">
      <div className="flex justify-end w-full px-10 py-10 md:py-16 md:pe-10">
        <div className="max-w-lg">
          <p className="mt-3 text-2xl font-bold whitespace-pre-line rudi-color sm:text-3xl">
            {title}
          </p>
          <p className="mt-3 whitespace-pre-line sm:text-lg">
            <Markup content={description} />
          </p>
        </div>
      </div>
      <div className="w-full">
        <div
          className="min-h-full bg-center bg-no-repeat bg-cover h-60"
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
    </div>
  );
}

export default ImageAsideRight;
