import React from "react";
import logo from "../../../assets/images/logo_icon.png";
import SignLinks from "./sign-links";
import postData from "../../../hooks/useFetch";

import { message, Input, Spin } from "antd";
import { isValidEmail } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";

function SignIn() {
  document.title = "Sign in";
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [data, setData] = React.useState({
    user_type: 3,
    email: "",
    password: "",
  });
  const input_class =
    "w-full focus:outline-none rounded-md text-sm px-3 py-2 mt-2";

  const saveToken = (userToken) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    // localStorage.setItem("token", JSON.stringify(userToken));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (data.email.trim().length < 3)
      message.error("Please enter Email Address");
    else if (!isValidEmail(data.email.trim()))
      message.error("Provide a valid Email Address");
    else if (data.password.trim().length < 3)
      message.error("Please enter a Password");
    else {
      setLoading(true);
      postData({
        service: "sign_in",
        data: data,
      }).then((data) => {
        if (data.success !== undefined && data.success === 1) {
          saveToken(data.token);
          sessionStorage.setItem(
            "service",
            JSON.stringify(data.data.merchant_id)
          );
          navigate("/dashboard");
          window.location.reload(false);
        } else message.error(data.message);
        setLoading(false);
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen main-bg">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-24" />
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-lg pb-6 mx-4 bg-white rounded-lg">
            <SignLinks />

            <form onSubmit={onSubmit}>
              <div className="px-6">
                <div className="mt-6">
                  <p className="text-sm ps-1">Email Address</p>
                  <Input
                    type="email"
                    placeholder="Enter Email address"
                    className={input_class}
                    value={data.email}
                    onChange={(e) => {
                      data.email = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>

                <div className="mt-4">
                  <p className="text-sm ps-1">Password</p>
                  <Input.Password
                    type="password"
                    placeholder="Enter Password"
                    className={input_class}
                    value={data.password}
                    visibilityToggle={{
                      visible: passwordVisible,
                      onVisibleChange: setPasswordVisible,
                    }}
                    onChange={(e) => {
                      data.password = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>

                <Link to={"/forgot-password"}>
                  <p className="mt-2 text-sm rudi-color"> Forgot password</p>
                </Link>

                <button
                  type="submit"
                  disabled={loading}
                  className="flex justify-center w-full gap-4 p-3 mt-6 text-sm font-semibold text-white rounded-md rudi-bg"
                >
                  {loading ? (
                    <>
                      <Spin /> Loading...
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
