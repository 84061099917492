/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Calendar } from "antd";
import { BsMinecartLoaded } from "react-icons/bs";
import postData from "../../hooks/useFetch";
import _ from "lodash";

import { Modal, Select, Input, Upload, message, Radio, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { useSelector, useDispatch } from "react-redux";
import { menu, menuAsync } from "../../features";
import { base_url } from "../../utils/constants";

const { Dragger } = Upload;

function Schedule() {
  document.title = "Schedule";

  const reload = useSelector(menu.reload);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [cargo, setCargo] = React.useState([]);
  const [loadData, setLoadData] = React.useState({});
  const [param, setParam] = React.useState({
    offset: "0",
    items: "100",
    search: "",
  });

  const [uploads, setUploads] = React.useState({});

  const [data, setData] = React.useState({
    cargo: "",
    loads: null,
    truck_type: null,
    weight_type: "kg",
    weight: null,
    payment_term: null,
    days: 0,
    rate_amount_type: null,
    rate_amount_currency: null,
    rate_amount: null,
    // git_currency: null,
    // git_per_load: 0,
    loading_point: null,
    loading_lat: null,
    loading_long: null,
    loading_date: null,
    loading_time: "00:00:00",
    offloading_point: null,
    offloading_lat: null,
    offloading_long: null,
    documents: [],
  });
  const onSelect = (newValue, i) => {
    if (i.source === "date" && Object.keys(loadData).length > 0) {
      setIsModalOpen(true);
      data.loading_date = newValue.format("YYYY-MM-DD");
      setData({ ...data });
    }
  };

  const dateCellRender = (value) => {
    const grouped = _.groupBy(cargo, "loading_date");
    const listData = grouped[value.format("YYYY-MM-DD")];
    return (
      <ul className="events">
        {listData !== undefined &&
          listData.map((item, i) => (
            <div
              key={i}
              className="flex items-center gap-2 text-xs font-medium text-red-600"
            >
              <BsMinecartLoaded />
              <p className="capitalize">
                {listData[0].cargo} ({listData[0].loads})
              </p>
            </div>
          ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  React.useEffect(() => {
    postData({
      service: "get_loads",
      data: param,
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) setCargo(data.data);
    });
  }, [param]);

  React.useEffect(() => {
    if (reload) {
      param.offset = 0;
      setParam({ ...param });
    }
  }, [reload]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    postData({
      service: "post_load_data",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        setLoadData(data.data);
      }
    });
  }, []);

  const onSubmitPost = () => {
    if (data.truck_type == null) message.error("Choose a vehicle type");
    else if (data.loads == null) message.error("How many trucks do you need?");
    else if (data.cargo.length === 0)
      message.error("Which cargo do you wish to transport?");
    else if (data.weight == null)
      message.error("What is the weight of your Cargo");
    else if (data.rate_amount_type == null)
      message.error("Choose the rate amount type");
    else if (data.rate_amount_type < 3 && data.rate_amount_currency == null)
      message.error("Choose the rate amount currency");
    else if (data.rate_amount_type < 3 && data.rate_amount == null)
      message.error("Enter rate amount");
    else if (data.payment_term == null) message.error("Choose a payment term");
    else if (data.loading_lat == null)
      message.error("Please enter the loading location");
    else if (data.loading_date == null)
      message.error("Please select the loading date");
    else if (data.loading_time == null)
      message.error("Please select the loading time");
    else if (data.offloading_lat == null)
      message.error("Please enter the offLoading location");
    else {
      data.documents = Object.values(uploads);
      postData({
        service: "post_load",
        data: data,
      }).then((data) => {
        if (data.success !== undefined && data.success === 1) {
          dispatch(menuAsync.reload(true));
          setUploads({});
          setData({
            cargo: "",
            loads: null,
            truck_type: null,
            weight_type: "kg",
            weight: null,
            payment_term: null,
            days: 0,
            rate_amount_type: null,
            rate_amount_currency: null,
            rate_amount: null,
            // git_currency: null,
            // git_per_load: 0,
            loading_point: null,
            loading_lat: null,
            loading_long: null,
            loading_date: null,
            loading_time: "00:00:00",
            offloading_point: null,
            offloading_lat: null,
            offloading_long: null,
            documents: [],
          });
          setIsModalOpen(false);
          message.success(data.message);
        } else message.error(data.message);
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setUploads({});
    setData({
      cargo: "",
      loads: null,
      truck_type: null,
      weight_type: "kg",
      weight: null,
      payment_term: null,
      days: 0,
      rate_amount_type: null,
      rate_amount_currency: null,
      rate_amount: null,
      // git_currency: null,
      // git_per_load: 0,
      loading_point: null,
      loading_lat: null,
      loading_long: null,
      loading_date: null,
      loading_time: "00:00:00",
      offloading_point: null,
      offloading_lat: null,
      offloading_long: null,
      documents: [],
    });
  };

  /** Handle all select changes */
  const onChange = (e, value) => {
    data[value] = e;
    setData({ ...data });
  };

  const props = {
    name: "file",
    multiple: false,
    action: base_url + "file_upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        _.map(info.fileList, (upload) => {
          uploads[upload.uid] = info.file.response.data[0];
          setUploads({ ...uploads });
        });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      if (status === "removed") {
        delete uploads[info.file.uid];
        setUploads({ ...uploads });
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const Selection = ({ placeholder, items, value }) => (
    <Select
      showSearch
      style={{ width: "100%" }}
      placeholder={placeholder}
      value={data[value]}
      optionFilterProp="children"
      onChange={(e) => onChange(e, value)}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={items.map((item) => ({
        value: `${item.number !== undefined ? item.number : ""}${
          item.id !== undefined ? item.id : ""
        }`,
        label: `${
          item.alpha3 !== undefined ? `${item.alpha3} - ${item.langEN}` : ""
        }${item.type !== undefined ? item.type : ""}${
          item.rate !== undefined ? item.rate : ""
        }${item.term !== undefined ? item.term : ""}`,
      }))}
    />
  );

  const handleChange = (addr) => {
    data.loading_point = addr;
    setData({ ...data });
  };

  const handleSelect = (address) => {
    data.loading_point = address;
    setData({ ...data });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        data.loading_lat = latLng.lat;
        data.loading_long = latLng.lng;
        setData({ ...data });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleChange1 = (addr) => {
    data.offloading_point = addr;
    setData({ ...data });
  };

  const handleSelect1 = (address) => {
    data.offloading_point = address;
    setData({ ...data });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        data.offloading_lat = latLng.lat;
        data.offloading_long = latLng.lng;
        setData({ ...data });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <>
      <div className="px-8 py-4 bg-white">
        {cargo.length > 0 && (
          <Calendar onSelect={onSelect} cellRender={cellRender} />
        )}
      </div>
      <Modal
        title="Post Load"
        open={isModalOpen}
        onOk={handleOk}
        width={1000}
        centered
        maskClosable={false}
        onCancel={handleCancel}
        footer={[]}
      >
        <div className="grid w-full gap-x-10 gap-y-6 lg:grid-cols-3 md:grid-cols-2">
          <div>
            <p className="mb-3">Choose type of truck</p>
            <Selection
              placeholder="Select vehicle type"
              value="truck_type"
              items={loadData.truck_type}
            />
          </div>
          <div>
            <p className="mb-3">Number of trucks</p>
            <Input
              placeholder="Number of trucks"
              type="number"
              className="no-spinners"
              value={data.loads}
              onChange={(e) => {
                data.loads = e.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div>
            <p className="mb-3">Commodity</p>
            <Input
              placeholder="Commodity"
              value={data.cargo}
              onChange={(e) => {
                data.cargo = e.target.value;
                setData({ ...data });
              }}
            />
          </div>

          <div>
            <p className="mb-3">Weight</p>
            <div className="flex gap-2">
              <Radio.Group
                value={data.weight_type}
                onChange={(e) => {
                  data.weight_type = e.target.value;
                  setData({ ...data });
                }}
                className="flex"
              >
                <Radio.Button value="kg">Kg</Radio.Button>
                <Radio.Button value="ton">Ton</Radio.Button>
              </Radio.Group>
              <Input
                placeholder="Weight"
                type="number"
                className="no-spinners"
                value={data.weight}
                onChange={(e) => {
                  data.weight = e.target.value;
                  setData({ ...data });
                }}
              />
            </div>
          </div>

          <div>
            <p className="mb-3">Rate Amount</p>
            <Selection
              placeholder="Select rate amount"
              value="rate_amount_type"
              items={loadData.rate_amount}
            />
            {data.rate_amount_type !== null && data.rate_amount_type < 3 && (
              <div className="flex gap-3 mt-2">
                <Selection
                  placeholder="KES - Kenyan Shilling"
                  value="rate_amount_currency"
                  items={loadData.currency}
                />
                <Input
                  placeholder="0"
                  type="number"
                  className="no-spinners"
                  value={data.rate_amount}
                  onChange={(e) => {
                    data.rate_amount = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            )}
          </div>

          <div>
            <p className="mb-3">Payment Terms</p>
            <div className="mt-2">
              <Selection
                placeholder="None Selected"
                value="payment_term"
                items={loadData.payment_terms}
              />

              {data.payment_term > 1 && (
                <div className="flex items-center gap-3 mt-2">
                  <Input
                    placeholder="0"
                    type="number"
                    className="w-24 no-spinners"
                    value={data.days}
                    onChange={(e) => {
                      data.days = e.target.value;
                      setData({ ...data });
                    }}
                  />
                  <p>Days</p>
                </div>
              )}
            </div>
          </div>

          {/* <div>
            <p className="mb-3">GIT per load</p>
            <div className="flex items-center gap-3 mt-2">
              <Selection
                placeholder="KES - Kenyan Shilling"
                value="git_currency"
                items={loadData.currency}
              />

              <Input
                placeholder="Git"
                type="number"
                className="no-spinners"
                value={data.git_per_load}
                onChange={(e) => {
                  data.git_per_load = e.target.value;
                  setData({ ...data });
                }}
              />
            </div>
          </div> */}
        </div>

        <div>
          {/* <p className="text-base font-medium ps-2">Order allocation</p> */}
          <div className="grid grid-cols-2 gap-6 mt-4">
            <div>
              <p className="py-2 text-sm ps-2">Loading Addresses</p>
              <PlacesAutocomplete
                value={data.loading_point}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Input
                      required
                      {...getInputProps({
                        placeholder: "Loading location",
                        className: `w-full px-3 py-2 mt-1 text-sm border rounded-sm focus:outline-none`,
                      })}
                    />
                    <div className="autocomplete-dropdown-containers">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : {
                              backgroundColor: "var(--div-bg)",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            style={{ width: "100%" }}
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <p className=" location-suggestion">
                              {suggestion.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div>
              <p className="py-2 text-sm ps-2">Offloading Addresses</p>
              <PlacesAutocomplete
                value={data.offloading_point}
                onChange={handleChange1}
                onSelect={handleSelect1}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Input
                      required
                      {...getInputProps({
                        placeholder: "Offloading location",
                        className: `w-full px-3 py-2 mt-1 text-sm border rounded-sm focus:outline-none`,
                      })}
                    />
                    <div className="autocomplete-dropdown-containers">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : {
                              backgroundColor: "var(--div-bg)",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            style={{ width: "100%" }}
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <p className=" location-suggestion">
                              {suggestion.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-4">Documents:</p>
          <p className="mb-3 text-gray-600">
            Please upload documents that will aid with the loading your cargo
            i.e cargo/load/ machine photos, parking list, picture of cargo, etc:
            jpg, jpeg, png, gif, pdf, htm, html, txt, zip
          </p>

          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </div>

        <div className="flex gap-4 mt-8">
          <Button onClick={handleCancel}>Close</Button>
          <Button className="text-white success-bg" onClick={onSubmitPost}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Schedule;
