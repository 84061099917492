import React from "react";
import { Modal, message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { system_url } from "../../utils/constants";

function TrackingLink() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const getMerchant = () => {
    const tokenString = sessionStorage.getItem("service");
    if (tokenString !== null) return tokenString.replace(/['"]+/g, "");
    return "";
  };

  const copy = () => {
    message.success("Copied to clipboard");
  };
  return (
    <div>
      <button
        className="px-8 py-2 text-sm border border-black rounded-md gold-bg"
        onClick={showModal}
      >
        Cargo Tracking
      </button>

      <Modal
        title="Tracking link"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[]}
      >
        <p>Click to copy to Clipboard</p>
        <p className="mt-3">{system_url + getMerchant()}</p>
        <CopyToClipboard text={system_url + getMerchant()} onCopy={copy}>
          <button className="text-blue-500 me-4">Click here</button>
        </CopyToClipboard>
      </Modal>
    </div>
  );
}

export default TrackingLink;
