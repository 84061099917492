import React from "react";

import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import postData from "../../hooks/useFetch";
import truck_blue from "../../assets/images/truck_blue.png";
import _ from "lodash";

const containerStyle = {
  width: "100%",
  height: "85vh",
};

const center = {
  lat: -1.286389,
  lng: 36.817223,
};

function TrackLoad() {
  const [trucks, setTrucks] = React.useState([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0dy46oTvw9PivnuoTzy_aa5LDp_8FNIo",
  });

  const [info, setInfo] = React.useState({});

  React.useEffect(() => {
    postData({
      service: "get_matched_loads",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        setTrucks(data.data);
      } else setTrucks([]);
    });
  }, []);
  return (
    <div>
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
          {_.map(trucks, (t) => (
            <>
              <MarkerF
                icon={{
                  url: truck_blue,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{
                  lat: t.latitude,
                  lng: t.longitude,
                }}
                onClick={() => setInfo(t)}
              />
              {Object.keys(info).length > 0 && info.truck_id === t.truck_id && (
                <InfoWindowF
                  position={{
                    lat: t.latitude,
                    lng: t.longitude,
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -25),
                  }}
                  onCloseClick={() => setInfo({})}
                >
                  <div className="marker_pop_up">
                    <h6>{info.truck_no}</h6>
                    <p>Driver : {info.driver_name}</p>
                    <p>{info.type}</p>

                    <p>
                      Tel: <a href={`tel:+${info.phone}`}>+{info.phone}</a>
                    </p>
                  </div>
                </InfoWindowF>
              )}
            </>
          ))}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TrackLoad;
