import React from "react";
import { Collapse } from "antd";

function Faq() {
  const items = [
    {
      key: "1",
      label: "Who can use the platform?",
      children: <p>Rudi is built import and export cargo owners, and cross border carriers, freight forwarders, and logistics service providers.</p>,
    },
    {
      key: "2",
      label: "How do I sign up on the platform as cargo owner?",
      children: <div>
        <p>Sign up on the Rudi Platform through our website www.ruditrip.com </p>
        <p>Provide the requested information,then await approval of the account opening by Rudi Admin.</p>
        <p>Once your account has been approved, you're free to post loads, schedule loads request for quotes, truck cargo, access invoices based on delivered cargo and more.</p>
      </div>,
    },
    {
      key: "3",
      label: "What types of cargo can be matched on the platform?",
      children: <p>Rudi focuses on load as from 15 tons all the way to 50 tons a truck. Since we have all types of trucks including Containerized trucks, Skeleton trucks, Flatbeds, Pulling trucks, Reefers trucks we have the capacity to carry most cargo.</p>,
    },
    {
      key: "4",
      label: "How do I post a cargo request?",
      children: <p>As a truck owner once you have downloaded the Rudi app and listed your trucks. Your able to request for loads within the application for approved trucks. </p>,
    },
    {
      key: "5",
      label: "How does the platform ensure the reliability of carriers?",
      children: <p>We meticulously vet our transport partners to ensure reliability, professionalism, and alignment with our values of innovation and sustainability</p>,
    },

  ];

  const items1 = [{
    key: "6",
    label: "What communication tools are available on the platform?",
    children: <p>Rudi has built a chat function within the application were the admin can communicate to all truck owners and drivers and they can communicate back. We have also made the provision for Truck owners to communicate with drivers and vise verse within the application. </p>,
  },
  {
    key: "7",
    label: "How is payment handled on the platform?",
    children: <p>Rudi sign direct contracts with Manufacturers and shippers, who pay based on the agreed payment terms, however, we pay our carriers 50% on loading and 50% on offloading or on offloading. We also provide escrow services to  safeguard both our cargo owners and carriers.</p>,
  },
  {
    key: "8",
    label: "Is the platform available internationally?",
    children: <p>Yes, Rudi is built with a focus for Africa at the moment. We are currently live in Kenya, Tanzania, Malawi, Zambia, Uganda and Rwanda. </p>,
  },
  {
    key: "9",
    label: "What happens in case of disputes or issues?",
    children: <p>With legal offices in Kenya and Tanzania we are governed by laws in both countries where disputes can be filled and resolved amicably.</p>,
  },]

  return (
    <div className="">
      <div className="container px-6 py-20 mx-auto">
        <p className="mb-6 text-3xl font-bold text-center rudi-color">
          Frequently Asked Questions
        </p>
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
          <div><Collapse accordion items={items} /></div>
          <div><Collapse accordion items={items1} /></div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
