import React from "react";
import HeaderBanner from "../../../components/header-banner";
import Contacts from "./contacts";
import Feedback from "./feedback";
import contact from "../../../assets/images/banner/contact.jpg";
import { Helmet } from "react-helmet";

function ContactUs() {
  const page = {
    title: "Contact us",
    description:
      "We're here to help you get started. Please complete this form to chat, book a meeting, or call our team directly",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={contact}
        title="Contact Us"
        description="We're here to help you get started. Please complete this form to chat, book a meeting, or call our team directly."
      />

      <Contacts />

      <Feedback />
    </div>
  );
}

export default ContactUs;
