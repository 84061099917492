import { Button, Modal, message } from "antd";
import React from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import postData from "../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { menuAsync } from "../../features";

const { confirm } = Modal;

function DeleteLoad({ cargo }) {
  const dispatch = useDispatch();

  const removeCargo = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Delete cargo",
      content: "Are you sure you want to remove this cargo",
      onOk() {
        return new Promise((resolve, reject) => {
          postData({
            service: "delete_load",
            data: { id: cargo.id },
          }).then((resp) => {
            if (resp.success === 0) message.error(resp.message);
            else {
              message.success(resp.message);
              dispatch(menuAsync.reload(true));
            }
            resolve();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  return (
    <>
      <Button danger icon={<DeleteOutlined />} onClick={removeCargo} />
    </>
  );
}

export default DeleteLoad;
