import React, { useState, useEffect } from "react";

import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import _ from "lodash";

import cross from "../../../assets/images/numbers/Map. EA, CA.png";
import cargo from "../../../assets/images/numbers/Cargo truck.jpg";
import carbon from "../../../assets/images/numbers/Carbon Emission.jpg";
import sdg from "../../../assets/images/numbers/SDG.png";
import distance from "../../../assets/images/numbers/tarmac-asphalt.jpg";

function Numbers() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const data = [
    {
      image: cross,
      description: "474 Cross-border trips made, Ke, UG, TZ, Zambia, Malawi",
    },
    {
      image: distance,
      description: "480,000 + Kms\ncovered",
    },
    {
      image: cargo,
      description: "14,220 + tons\nTransported",
    },
    {
      image: carbon,
      description: "181 Lbs of Carbon\nEmissions eliminated",
    },
    {
      image: sdg,
      description: "In Line with\n4 SDGs",
    },
  ];

  return (
    <div className="container py-10 mx-auto">
      <p className="text-2xl font-bold text-center rudi-color sm:text-3xl">
        Rudi in numbers
      </p>

      <Swiper
        spaceBetween={1}
        modules={[Autoplay, Pagination, Navigation, A11y]}
        slidesPerView={
          windowSize[0] < 1200
            ? windowSize[0] < 1000
              ? windowSize[0] < 800
                ? 2
                : 3
              : 4
            : 5
        }
        navigation
        pagination={{ clickable: true }}
        className="mt-10"
      >
        {_.map(data, (n, k) => (
          <SwiperSlide key={k}>
            <div className="">
              <div className="flex justify-center">
                <img
                  src={n.image}
                  alt="Numbers"
                  className="object-cover w-40 h-40 rounded-full"
                />
              </div>
              <div className="">
                <h6 className="px-4 mt-4 text-base text-center">
                  {n.description}
                </h6>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Numbers;
