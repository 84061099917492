import React from "react";
import logo from "../../assets/images/logo_icon.png";
import { useSelector } from "react-redux";
import { user } from "../../features";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message } from "antd";
import postData from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

function Invitation() {
  document.title = "Invitation";
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const logout = () => {
    confirm({
      title: "Are you sure, you want to logout?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, you will be logged out.",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          sessionStorage.clear();
          navigate("/");
          window.location.reload(false);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        navigate("/");
      },
    });
  };
  const accept = () => {
    if (loading === false) {
      setLoading(true);
      postData({
        service: "handle_invitation",
        data: {
          accept: true,
        },
      }).then((data) => {
        setLoading(false);
        if (data.success !== undefined && data.success === 1) {
          message.success(data.message);
          setTimeout(function () {
            window.location.reload(false);
          }, 2000);
        } else message.error(data.message);
      });
    }
  };
  const decline = () => {
    if (loading === false) {
      setLoading(true);
      confirm({
        title: "Are you sure you want to decline this invitation?",
        icon: <ExclamationCircleFilled />,
        content: "When clicked the OK button, Invitation will be declined",
        onOk() {
          return new Promise((resolve, reject) => {
            postData({
              service: "handle_invitation",
              data: {
                accept: false,
              },
            }).then((data) => {
              resolve();
              setLoading(false);
              if (data.success !== undefined && data.success === 1) {
                message.success(data.message);
                setTimeout(function () {
                  window.location.reload(false);
                }, 2000);
              } else message.error(data.message);
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    }
  };

  const profile = useSelector(user.profile),
    invited_by = profile.invited_by;

  return (
    <div className="flex items-center justify-center w-full h-screen main-bg">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-28" />
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-lg p-6 mx-4 mt-4 bg-white rounded-lg">
            <p className="mb-3 text-2xl font-semibold text-center">
              Invitation To Join a Team
            </p>
            <p className="mx-8 text-center">
              You have been invited by <b>{invited_by.name}</b> to collaborate
              as a Team. To accept this invitation, follow the link below.
            </p>

            <div className="flex justify-center mt-10">
              <button
                onClick={accept}
                className="px-12 py-3 font-medium text-white rounded-full rudi-bg"
              >
                <p>Accept Invitation</p>
              </button>
            </div>

            <p
              onClick={decline}
              className="mt-4 font-bold text-center text-red-800 cursor-pointer"
            >
              Decline Invitation
            </p>
            <p
              onClick={logout}
              className="mt-8 font-medium text-center text-red-800 cursor-pointer"
            >
              logout
            </p>

            <div className="p-4 mt-10 bg-gray-300 rounded-lg">
              <p>
                The Team Onwer will provide you with access and permissions to
                all data in the oragnization, so that you can easily and
                accurately collect, manage and report the Organizations
                operations
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invitation;
