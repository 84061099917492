import { Markup } from 'interweave'
import React from 'react'
import { Link } from 'react-router-dom'

function BlogItem({ post }) {
    return (
        <Link to={`/blog/${post.slug}`}>
            <div className='flex gap-3 p-4 overflow-hidden'>
                <div className='w-full font-serif'>
                    <h2 className='mb-2 text-xl font-medium line-clamp-2'>{post.title}</h2>
                    <div className='line-clamp-1'>
                        <Markup content={post.description} />
                    </div>

                </div>
                <div className='w-36'>
                    <img src={post.image} alt={post.title} className='object-cover w-full' />
                </div>
            </div>
        </Link>
    )
}

export default BlogItem
