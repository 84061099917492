import "./App.css";
import MainLanding from "./layout/landing/main";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SignIn from "./pages/landing/welcome/sign-in";
import SignUp from "./pages/landing/welcome/sign-up";
import MainManage from "./layout/dashboard/main";
import Tracking from "./pages/landing/tracking";
import AppPage from "./pages/landing/home/app-page";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import ForgotPassword from "./pages/landing/welcome/forgot-password";

const getToken = () => {
  // const tokenString = localStorage.getItem("token");
  const tokenString = sessionStorage.getItem("token");

  return tokenString;
};

function App() {

  const token = getToken();

  return (
    <div className="App">
      <TawkMessengerReact
        propertyId="65e61b4c8d261e1b5f689237"
        widgetId="1ho5dl941"
      />
      <BrowserRouter>
        <Routes>
          {token ? (
            <Route path="*" element={<MainManage />} />
          ) : (
            <>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/tracking/:id" element={<Tracking />} />
              <Route path="/app" element={<AppPage />} />
              <Route path="*" element={<MainLanding />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
