import React from "react";
import logo from "../../../assets/images/logo_icon.png";
import postData from "../../../hooks/useFetch";

import { message, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";

function ForgotPassword() {
  document.title = "Sign in";
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [otp, setOtp] = React.useState("");
  const [otpDis, setOtpDis] = React.useState(false);

  const [confPasswordVisible, setConfPasswordVisible] = React.useState(false);
  const [confPassword, setConfPassword] = React.useState("");

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [data, setData] = React.useState({
    user_type: 3,
    email_id: "",
  });
  const input_class =
    "w-full focus:outline-none rounded-md text-sm px-3 py-2 mt-2";

  /** Verify entered code */
  const verifyOTP = () => {
    postData({
      service: "verify_otp",
      data: {
        otp: otp,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        setPage(2);
      } else {
        setOtpDis(false);
        message.error(data.message);
      }
    });
  };

  /** Request OTP code */
  const requestOTP = (e) => {
    e.preventDefault();
    setLoading(true);
    postData({
      service: "forgot_password",
      data: {
        user_type: 3,
        email_id: data.email_id,
      },
    }).then((data) => {
      setLoading(false);
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        setPage(1);
      } else message.error(data.message);
      setLoading(false);
    });
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span className="text-red-500 cursor-pointer" onClick={requestOTP}>
          Request another one
        </span>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  React.useEffect(() => {
    if (otp.toString().length === 6) {
      setOtpDis(true);
      verifyOTP();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const passwordReset = (e) => {
    e.preventDefault();
    setLoading(true);
    postData({
      service: "verify_otp",
      data: {
        otp: otp,
        password: data.password,
      },
    }).then((data) => {
      setLoading(false);
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        setTimeout(function () {
          navigate("/sign-in");
        }, 2000);
      } else message.error(data.message);
      setLoading(false);
    });
  };

  return (
    <div className="flex items-center justify-center w-full h-screen main-bg">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-24" />
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-md pb-6 mx-4 bg-white rounded-lg">
            {page === 0 && (
              <form onSubmit={requestOTP}>
                <h2 className="mt-4 font-semibold text-center">
                  Forgot Password
                </h2>
                <div className="px-6">
                  <div className="mt-6">
                    <p className="text-sm ps-1">Email Address</p>
                    <Input
                      type="email"
                      placeholder="Enter Email address"
                      className={input_class}
                      value={data.email_id}
                      onChange={(e) => {
                        data.email_id = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={loading}
                    className="flex justify-center w-full gap-4 p-3 mt-6 text-sm font-semibold text-white rounded-md rudi-bg"
                  >
                    {loading ? (
                      <>
                        <Spin /> Loading...
                      </>
                    ) : (
                      "Request OTP"
                    )}
                  </button>
                </div>
              </form>
            )}

            {page === 1 && (
              <div className="flex justify-center">
                <div className="w-full mx-4 mt-4 bg-white rounded-lg">
                  <p className="mb-3 text-2xl font-semibold text-center">
                    Email Verification
                  </p>
                  <p className="mx-8 text-center">
                    Please use the One Time Password (OTP) that was sent to your
                    email address <b>{data.email_id}</b> to verify
                  </p>

                  <div className="flex justify-center mt-5">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType="number"
                      renderSeparator={<span className="w-3"></span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          disabled={otpDis}
                          className="text-xl border border-black rounded-md no-spinners"
                        />
                      )}
                    />
                  </div>

                  {otpDis && (
                    <div className="flex items-center justify-center gap-4 mt-3">
                      <Spin /> <p>Verifying, please wait...</p>
                    </div>
                  )}

                  <p className="mt-6 text-center">
                    Haven't recieved the OTP code.{" "}
                    <Countdown
                      key={"sample"}
                      date={Date.now() + 30 * 1000}
                      renderer={renderer}
                    />
                  </p>
                </div>
              </div>
            )}
            {page === 2 && (
              <form onSubmit={passwordReset}>
                <h2 className="mt-4 font-semibold text-center">
                  Password Reset
                </h2>
                <div className="px-6">
                  <div className="mt-4">
                    <p className="text-sm ps-1">Password</p>
                    <Input.Password
                      required
                      placeholder="Enter Password"
                      className={input_class}
                      value={data.password}
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                      onChange={(e) => {
                        data.password = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="text-sm ps-1">Confirm Password</p>
                    <Input.Password
                      required
                      placeholder="Confirm Password"
                      className={input_class}
                      value={confPassword}
                      visibilityToggle={{
                        visible: confPasswordVisible,
                        onVisibleChange: setConfPasswordVisible,
                      }}
                      onChange={(e) => setConfPassword(e.target.value)}
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={loading}
                    className="flex justify-center w-full gap-4 p-3 mt-6 text-sm font-semibold text-white rounded-md rudi-bg"
                  >
                    {loading ? (
                      <>
                        <Spin /> Loading...
                      </>
                    ) : (
                      "Set password"
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
