import React from "react";
import HeaderBanner from "../../../components/header-banner.js";
import blogbg from "../../../assets/images/banner/blog.jpg";
import { Helmet } from "react-helmet";
import blog from "../../../assets/images/blog.png";
import { admin_post } from "../../../hooks/useFetch.js";
import BlogItem from "./blog-item.js";
import { Markup } from "interweave";
import { Link } from "react-router-dom";

function Blog() {
  const [posts, setPosts] = React.useState([]);
  const page = {
    title: "Our Blog",
    description:
      "Welcome to our blog, the heartbeat of our online community",
  };

  const blogs = () => {
    admin_post({
      service: "blog_posts",
      data: {
        offset: "0",
        items: "10",
      },
    }).then((resp) => {
      if (resp.success === 1) setPosts(resp.data);
    });
  };

  React.useEffect(() => {
    blogs();
  }, []);
  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={blogbg}
        title="Our Blog"
        description="Welcome to our blog, the heartbeat of our online community"
      />
      <div className="container py-16 mx-auto">
        {posts.length > 0 ? (
          <>
            <Link to={`/blog/${posts[1].slug}`}>
              <div className="flex flex-col gap-10 pb-6 mb-10 border-b border-black lg:flex-row">
                <div className="w-full lg:w-1/3">
                  <h2 className="font-serif text-4xl font-light md:text-6xl">{posts[1].title}</h2>
                  <div className="my-10 font-serif text-xl font-light lg:text-2xl line-clamp-6">
                    <Markup content={posts[1].description} />
                  </div>
                  <p className="font-serif text-lg">Read more ...</p>
                </div>
                <div className="w-full lg:w-2/3">
                  <img src={posts[1].image} alt={posts[1].title} className='aspect-[2/1] object-cover' />
                </div>
              </div>
            </Link>
            <div className="grid grid-cols-1 divide-x lg:grid-cols-3 md:grid-cols-2">
              {posts.map((post, i) => {
                if (i === 1) return <></>
                return <BlogItem post={post} key={i} />
              })}
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center w-full text-center">
            <img
              src={blog}
              alt="No blog posts"
              className="object-contain h-24"
            />
            <p>No Posts available</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
