import React from "react";
import { Routes, Route } from "react-router-dom";
import MyTeam from "../pages/my-team";
import Loads from "../pages/loads";
import Settings from "../pages/settings";
import Dashboard from "../pages/dashboard";
import ErrorPage from "./error-page";
import Department from "../pages/department";
import Roles from "../pages/roles";
import Schedule from "../pages/schedule";
import TrackLoad from "../pages/loads/track-load";
import Documents from "../pages/documents";

function ContentManage() {
  return (
    <div className="h-full px-4 pt-16 overflow-x-auto overflow-y-auto bg-gray-300">
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/cargo" element={<Loads />} />
        <Route path="/track" element={<TrackLoad />} />
        <Route path="/my-team" element={<MyTeam />} />
        <Route path="/department" element={<Department />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default ContentManage;
