/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AiOutlineExport, AiOutlineReload } from "react-icons/ai";
// import { BiFilterAlt } from "react-icons/bi";
import _ from "lodash";
import postData from "../../hooks/useFetch";
import { Tooltip, Typography } from "antd";
import { formatDate, numberFormatter } from "../../utils/utils";

import { useSelector, useDispatch } from "react-redux";
import { menu, menuAsync } from "../../features";
import LoadDetails from "./load-details";
import moment from "moment";
import EditLoad from "./edit-load";
import DeleteLoad from "./delete-load";

const { Text } = Typography;
export function RequestedLoad() {
  const reload = useSelector(menu.reload);
  const dispatch = useDispatch();

  const [options, setOptions] = React.useState({
    requested: 0,
    accepted: 0,
    in_transit: 0,
    completed: 0,
  });

  const [data, setData] = React.useState([]);
  const [param, setParam] = React.useState({
    offset: "0",
    items: "20",
    search: "",
  });

  const header = () => {
    postData({
      service: "cargo_header",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        setOptions({ ...data.data });
    });
  };

  React.useEffect(() => {
    header();
    postData({
      service: "get_loads",
      data: param,
    }).then((data) => {
      dispatch(menuAsync.reload(false));
      if (data.success !== undefined && data.success === 1) setData(data.data);
    });
  }, [param]);

  React.useEffect(() => {
    if (reload) {
      param.offset = 0;
      setParam({ ...param });
    }
  }, [reload]);

  /** Match Status */
  const match = (load) => {
    let ends = [];
    let starts = [];
    let complete = 0;
    load.match.map((m) => {
      if (m.end_trip !== null) ends.push(m);
      if (m.start_trip !== null) starts.push(m);
      return m;
    });

    if (starts.length === ends.length) complete = ends.length;

    if (load.loads === complete) return <Text type="success">Delivered</Text>;
    if (starts.length > 0) return <Text type="warning">ONroute</Text>;
    return <Text type="danger">Pending</Text>;
  };

  return (
    <>
      <div className="flex justify-end mt-6">
        <div
          className={`bg-white border border-black rounded-md gap-x-10 px-4 py-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4`}
        >
          <div className="flex gap-2">
            <p className="text-sm">Requested</p>
            <p className="px-3 text-sm text-white rounded-md dark-blue-bg">
              {options.requested}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="text-sm">Accepted</p>
            <p className="px-3 text-sm text-white rounded-md dark-blue-bg">
              {options.accepted}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="text-sm">In Transit</p>
            <p className="px-3 text-sm text-white rounded-md dark-blue-bg">
              {options.in_transit}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="text-sm">Completed</p>
            <p className="px-3 text-sm text-white rounded-md dark-blue-bg">
              {options.completed}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6 max-w-[100%] overflow-hidden grid bg-white rounded-md px-4">
        <div className="grid items-center justify-between gap-4 p-3 sm:flex">
          <p className="font-semibold">Requested Loads</p>
          {/* <button className="flex items-center gap-3 px-6 py-2 ">
          <BiFilterAlt /> <p className="text-sm">Add filters</p>
        </button> */}

          <div className="flex items-center gap-3">
            <button className="flex items-center gap-3 px-6 py-2 text-white rounded-md success-bg">
              <AiOutlineExport /> <p className="text-sm">Export</p>
            </button>

            <Tooltip title="Reload">
              <button
                className="flex items-center gap-3 px-4 py-2 bg-gray-200 rounded-md"
                onClick={() => {
                  param.offset = 0;
                  setParam({ ...param });
                }}
              >
                <AiOutlineReload />
              </button>{" "}
            </Tooltip>
          </div>
        </div>

        <div className="relative w-full h-[calc(100vh_-_18rem)] mb-4 overload-table">
          <table className="absolute top-0 w-full h-full overflow-y-auto">
            <thead className="sticky top-0">
              <tr style={{ backgroundColor: "rgb(240,240,240)" }}>
                <th>Action</th>
                <th>Delivered</th>
                <th>Order no</th>
                <th>Vehicle Type(s)</th>
                <th>Commodity</th>
                <th>No of trucks</th>
                <th>Weight</th>
                <th>Loading Point</th>
                <th>Loading Date</th>
                <th>Loading End</th>
                <th>Loading Time</th>
                <th>Offloading Point</th>
                <th>Rate</th>
                <th>Rate Amount</th>
                <th>Payment Term</th>
                <th>Days</th>
                <th>Git per load</th>
                <th>Posted by</th>
                <th>Date Created</th>
              </tr>
            </thead>
            <tbody>
              {_.map(data, (load, i) => (
                <tr key={i}>
                  <td>
                    <div className="flex gap-3">
                      <LoadDetails cargo={load} />
                      <EditLoad cargo={load} />
                      <DeleteLoad cargo={load} />
                    </div>
                  </td>
                  <td>{match(load)}</td>
                  <td>{load.id}</td>
                  <td>
                    {_.map(load.type, (type, i) => (
                      <div key={i}>
                        <p>{type.type}</p>
                      </div>
                    ))}
                  </td>
                  <td className="capitalize">{load.cargo}</td>
                  <td className="text-end">{numberFormatter(load.loads)}</td>
                  <td>
                    {numberFormatter(load.weight)} {load.weight_type}
                  </td>
                  <td>{load.loading_point}</td>
                  <td>{formatDate(load.loading_date)}</td>
                  <td>{formatDate(load.loading_end)}</td>
                  <td>{moment(load.loading_time, "HH:mm").format("h:m A")}</td>
                  <td>{load.offloading_point}</td>
                  <td>{load.rate}</td>
                  <td className="text-end">
                    {load.rate_amount_currency_alpha}.{" "}
                    {numberFormatter(load.rate_amount)}
                  </td>
                  <td>{load.term}</td>
                  <td>{load.days}</td>
                  <td className="text-end">
                    {load.git_currency_alpha}.{" "}
                    {numberFormatter(load.git_per_load)}
                  </td>
                  <td>{load.name}</td>
                  <td>{formatDate(load.creation_datetime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
