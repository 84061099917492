import React from "react";

import TruckOwners from "./truck-owners";
import Testimonial from "./testimonial";
import Banner from "./banner";
import Description from "./description";
import Numbers from "./numbers";
import { Helmet } from "react-helmet";
import Faq from "../about/faq"

function Home() {
  const page = {
    title: "Rudi Trip",
    description:
      "Rudi Corporation Limited is a logistics tech start-up, that serves shippers and carriers across the African continent by matching cargo to trucks in country and cross border, eliminating empty backhaul, process payments, and manage fleets",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>

      <Banner />

      <Description />

      <TruckOwners />

      <Testimonial />

      <Numbers />

      <Faq />
    </div>
  );
}

export default Home;
