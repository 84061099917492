import React from "react";
import PhoneInput from "react-phone-input-2";

function Feedback() {
  const input_class =
    "w-full py-2 px-4 rounded-sm text-base border focus:outline-none";
  return (
    <div className="bg-gray-200">
      <div className="container grid grid-cols-1 py-16 mx-auto lg:grid-cols-2">
        <div className="px-8 lg:ps-12 md:ps-10">
          <p className="text-xl font-bold rudi-color">Questions or feedback?</p>
          <p className="mt-3 sm:text-lg">
            Have an enquiry? Contact us using the form on the right or send us
            an email and we will get back to you swiftly.
          </p>
        </div>
        <div className="px-8 mt-6 lg:mt-0">
          <div className="w-full ">
            <div>
              <p className="text-base ps-1">Name</p>
              <div className="grid w-full grid-cols-1 gap-4 mt-2 sm:grid-cols-2">
                <input
                  type="text"
                  placeholder="First name"
                  className={input_class}
                />
                <input
                  type="text"
                  placeholder="Last name"
                  className={input_class}
                />
              </div>
            </div>

            <div className="mt-4">
              <p className="text-base ps-1">Email</p>
              <input
                type="email"
                placeholder="Email"
                className={`${input_class} mt-2`}
              />
            </div>

            <div className="mt-4">
              <p className="text-base ps-1">Message</p>
              <textarea
                className={`${input_class} mt-2`}
                placeholder="Leave us a message"
                rows={4}
              ></textarea>
            </div>
            <div className="mt-4">
              <p className="text-base ps-1">Phone</p>
              <div className="mt-2 phone-number">
                <PhoneInput
                  countryCodeEditable={false}
                  country={"ke"}
                  enableSearch={true}
                  required={true}
                />
              </div>
            </div>

            <button className="rudi-bg font-semibold w-full border-0 text-white rounded-md mt-6 text-sm p-3.5">
              Send message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
