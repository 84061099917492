import React from "react";
import { Empty } from "antd";

function ErrorPage() {
  document.title = "Page not found";
  return (
    <div>
      <Empty />
    </div>
  );
}

export default ErrorPage;
