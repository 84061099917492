import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { setProfile } = userSlice.actions;

export const userAsync = {
  profile: (profile) => (dispatch) => {
    dispatch(setProfile(profile));
  },
};

export const user = {
  profile: (state) => state.user.profile,
};

export default userSlice.reducer;
