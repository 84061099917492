import React, { useState } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { Modal, Input, message } from "antd";
import postData from "../../hooks/useFetch";

import { useSelector } from "react-redux";
import { user } from "../../features";

function CreateTeam() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = useSelector(user.profile);

  const [team, setTeam] = useState("");

  const showModal = () => {
    setTeam("");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    onSubmit();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    postData({
      service: "create_team",
      data: {
        team: team,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);

        setIsModalOpen(false);

        setTimeout(function () {
          window.location.reload(false);
        }, 3000);
      }
      message.error(data.message);
    });
  };

  return (
    <div>
      {profile.team === null && profile.role_id === 0 && (
        <button
          onClick={showModal}
          className="flex items-center gap-3 px-6 py-2 text-white rounded-md success-bg"
        >
          <AiOutlineTeam /> <p className="text-sm">Create Team</p>
        </button>
      )}

      <Modal
        title="Create Team"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="mb-2">Team Name</p>
        <Input
          type="text"
          value={team}
          placeholder="Enter the team name"
          onChange={(e) => setTeam(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default CreateTeam;
