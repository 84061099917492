import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function SignLinks() {
  const [active, setActive] = useState("");

  let location = useLocation();
  useEffect(() => {
    setActive(location.pathname.substring(1));
    window.scrollTo({ behavior: "smooth", top: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /** Go back to home when back pressed */
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function (event) {
      window.location.replace(`/`);
    });
  }, []);

  return (
    <div className="grid grid-cols-2 border-b">
      <Link
        to={"/sign-in"}
        className={`py-3 text-base font-medium text-center ${
          active === "sign-in" && "active"
        }`}
      >
        Sign In
      </Link>
      <Link
        to={"/sign-up"}
        className={`py-3 text-base font-medium text-center border-s ${
          active === "sign-up" && "active"
        }`}
      >
        Create account
      </Link>
    </div>
  );
}

export default SignLinks;
