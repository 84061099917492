import React from "react";

function AppPage() {
  window.location.href =
    "https://play.google.com/store/apps/details?id=com.ruditrip&hl=en&gl=US";

  return <div>Loading...</div>;
}

export default AppPage;
