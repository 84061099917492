import React from "react";

function Mission() {
  return (
    <div className="bg-gray-100">
      <div className="container px-10 py-16 mx-auto sm:px-6">
        <div className="grid gap-5 lg:grid-cols-3 md:ms-10">
          <div>
            <p className="text-xl font-bold rudi-color">Problem statement</p>
            <p className="tracking-wide">
              High cost of transport constituting upto 30% of the cost of Goods.
              This is a huge hindrance to intra-regional trade in Africa.
            </p>
          </div>

          <div><p className="text-xl font-bold rudi-color">Purpose</p>
            <p className="tracking-wide">
              To reduce cost of cross border transportation hence
              promoting intra Africa trade.
            </p></div>

          <div><p className="text-xl font-bold rudi-color">Our ambition</p>
            <p className="tracking-wide">
              is to leverage cutting-edge technology to reduce empty
              miles, high carbon emissions and increase the time to find cargo.
            </p></div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
