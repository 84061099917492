import React from "react";
import features from "../../../assets/images/app/features1.png";
import { FaLocationCrosshairs, FaMoneyBill1Wave } from "react-icons/fa6";
import { FaTruckLoading } from "react-icons/fa";

function AppFeatures() {
  return (
    <div className="px-6 py-24 bg-gray-100">
      <p className="pb-12 text-4xl font-bold text-center rudi-color">
        Rudi App Features
      </p>
      <div className="grid max-w-5xl gap-10 mx-auto mt-6 font-medium md:grid-cols-3 md:text-xl">
        <div className="flex flex-col justify-between gap-6">
          <div>
            <div className="flex">
              <div className="p-4 mb-3 text-xl text-white rounded-full md:text-3xl rudi-bg">
                <FaTruckLoading />
              </div>
            </div>
            <p>Find Cargo posted on the platform by cargo owner</p>
          </div>
          <div>
            <div className="flex">
              <div className="p-4 mb-3 text-xl text-white rounded-full md:text-3xl rudi-bg">
                <FaLocationCrosshairs />
              </div>
            </div>
            <p>Cargo Tracking being transported by Drivers</p>
          </div>
        </div>
        <div className="">
          <img
            src={features}
            alt="Rudi app features"
            className="object-contain w-full h-full max-h-52 md:max-h-80"
          />
        </div>
        <div className="flex flex-col justify-between gap-6">
          <div>
            <div className="flex">
              <div className="p-4 mb-3 text-xl text-white rounded-full md:text-3xl rudi-bg">
                <FaMoneyBill1Wave />
              </div>
            </div>
            <p>Access to financing for trips to be made easily and flexibly</p>
          </div>
          <div>
            <div className="flex">
              <div className="p-4 mb-3 text-xl text-white rounded-full md:text-3xl rudi-bg">
                <FaLocationCrosshairs />
              </div>
            </div>
            <p>Cargo Tracking</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppFeatures;
