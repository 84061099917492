import React from "react";
import PostLoad from "./post-load";
import { RequestedLoad } from "./requested-load";
import { Link } from "react-router-dom";
import TrackingLink from "./tracking_link";

function Loads() {
  document.title = "Cargo";

  return (
    <div>
      <div className="flex justify-end gap-6">
        <PostLoad />

        <Link
          to="/track"
          className="px-8 py-2 text-sm border border-black rounded-md gold-bg"
        >
          Track Loads
        </Link>

        <TrackingLink />
      </div>

      <RequestedLoad />
    </div>
  );
}

export default Loads;
