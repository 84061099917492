import React from "react";
import HeaderBanner from "../../components/header-banner";
import privacy from "../../assets/images/banner/privacy.jpg";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const page = {
    title: "Privacy Policy",
    description:
      "Legal document disclosing information about how Rudi gathers, uses, discloses, and manages a customer and client's data",
  };

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <HeaderBanner
        image={privacy}
        title="Privacy Policy"
        description="Legal document disclosing information about how Rudi gathers, uses, discloses, and manages a customer and client's data"
      />

      <div className="container px-5 pt-5 pb-10 mx-auto">
        <ol className="list-none document-list">
          <li>
            <h2>Scope</h2>
            <p>
              This Privacy Policy describes how we handle your personal
              information for our Service on the Rudi websites, tools, and
              mobile applications. It applies generally to information collected
              on the (
              <a href="https://ruditrip.com" target={"_blank"} rel="noreferrer">
                https://ruditrip.com
              </a>
              ) website, (Rudi) mobile application and content (collectively,
              the "Site" or “Sites”) or through the use of our Service.
              Capitalized terms used in this Privacy Policy shall have the
              meaning set forth herein or in the User Agreement posted on the
              Site. If you established a Rudi account before the “last revised”
              date above, this Privacy Policy is effective as of May 26th, 2023.
              For all other individuals, the Privacy Notice is effective
              immediately.
            </p>
          </li>

          <li>
            <h2>Types of Data We Collect</h2>
            <p>We may collect and store the following Personal Data:</p>
            <ul>
              <li>
                <h4>Information You Provide to Us.</h4>
                <ul>
                  <li>
                    Contact information, such as name, user ID, e-mail address,
                    and phone number
                  </li>
                  <li>
                    Marketing information, such as your preferences for
                    receiving marketing communications and details about how you
                    engage with marketing communications; and
                  </li>
                  <li>Other personal information you choose to submit to us</li>
                </ul>
              </li>
              <br />
              <li>
                <h4>
                  Information We Collect About Truck owners From or on Behalf of
                  Our Shippers.
                </h4>
                <p>
                  We may collect information about truck owners from or on
                  behalf of our Shippers. The Shippers determine the scope of
                  the information we collect on their behalf, that they transfer
                  to us, or that we otherwise collect, and the information we
                  receive may vary by Shipper. Typically, the information we
                  collect about individuals from or on behalf of our shippers
                  includes:
                </p>
                <ul>
                  <li>
                    transactional information based on your activities on the
                    Site as a truck owner (such as item and content you
                    purchase)
                  </li>
                  <li>
                    your contact information (such as your name, user ID, email
                    address, and, and other information you provide to receive a
                    successful cargo match.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h4>Information Automatically Collected.</h4>
                <p>
                  When you access the Site, we, our service providers and our
                  business partners may automatically log information about you,
                  your computer or mobile device, and activity on our Site. The
                  information that may be collected automatically includes your
                  computer or mobile device operating system type and version
                  number, manufacturer and model, device identifier (such as the
                  Google Advertising ID or Apple ID for Advertising), browser
                  type, screen resolution, IP address, the website you visited
                  before browsing to our Site, general location information such
                  as city, state or geographic area; and information about your
                  use of and actions on our Sites, such as pages you viewed, how
                  long you spent on a page, navigation paths between pages,
                  information about your activity on a page, access times, and
                  length of access. Our service providers and business partners
                  may collect this type of information over time and across
                  third-party websites. This information is collected using
                  cookies and similar technologies. Please refer to the Cookies
                  and Similar Technologies section for more details.
                </p>
              </li>

              <li>
                <h4>Sensitive Personal Information. </h4>
                <p>
                  We ask that you not send us, and you not disclose, any
                  sensitive personal information (e.g., social security numbers,
                  information related to racial or ethnic origin, political
                  opinions, religion or other beliefs, health, biometrics or
                  genetic characteristics, criminal background or trade union
                  membership) on the Sites, or otherwise to us. If you send or
                  disclose any sensitive personal information to us when you use
                  the Services, you must consent to our processing and use of
                  such sensitive personal information in accordance with this
                  Privacy Policy. If you do not consent to our processing and
                  use of such sensitive personal information, you must not
                  submit such sensitive personal information through our Sites.
                </p>
              </li>
            </ul>
          </li>

          <li>
            <h2>Cookies and Similar Technologies</h2>
            <h4>What are cookies?</h4>

            <p>
              Cookies are small data files stored on your computer or mobile
              device by a website. Our Sites may use both session cookies (which
              expire once you close your web browser) and persistent cookies
              (which stay on your computer or mobile device until you delete
              them) to provide you with a more personal and interactive
              experience on our Site.
            </p>
            <p>
              We use two broad categories of cookies: (1) first-party cookies,
              served directly by us to your computer or mobile device, which we
              use to recognize your computer or mobile device when it revisits
              our Sites; and (2) third party cookies, which are served by
              service providers or business partners on our Sites, and can be
              used by such service providers or business partners to recognize
              your computer or mobile device when it visits other websites.
            </p>

            <h4>Cookies we use</h4>
            <p>
              Our Site uses the following types of cookies for the purposes set
              out below:
            </p>
            <ul>
              <li>
                <p>
                  Essential Cookies
                  <br /> These cookies are essential to provide you with
                  services available through our Site and to enable you to use
                  some of its features. Without these cookies, the services that
                  you have asked for cannot be provided, and we only use these
                  cookies to provide you with those services.
                </p>
              </li>
              <li>
                <p>
                  Functionality Cookies
                  <br />
                  These cookies allow our Sites to remember choices you make
                  when you use our Sites. The purpose of these cookies is to
                  provide you with a more personal experience and to avoid you
                  having to re-select your preferences every time you visit our
                  Site.
                </p>
              </li>
              <li>
                <p>
                  Analytics and Performance Cookies
                  <br />
                  These cookies collect information about traffic to our Sites
                  and about how individuals use our Sites. The information
                  gathered may include the types of information described above
                  in the section titled “Information automatically collected.”
                  We use this information to help operate our Site more
                  efficiently, to gather broad demographic information, monitor
                  the level of activity on our Site, and improve the Site. We
                  use Google Analytics, Optimizely, and New Relic for this
                  purpose. These parties use their own cookies. You can find out
                  more information about Google Analytics cookies here:
                  <a
                    href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
                  </a>
                  , and about how Google protects your data here:
                  <a
                    href="www.google.com/analytics/learn/privacy.html"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.google.com/analytics/learn/privacy.html
                  </a>
                  . You can prevent the use of Google Analytics relating to your
                  use of our Site by downloading and installing the browser
                  plugin available here:
                  <a
                    href="tools.google.com/dlpage/gaoptout?hl=en-GB"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    tools.google.com/dlpage/gaoptout?hl=en-GB
                  </a>
                  . You can find out more about New Relic’s services here:
                  <a
                    href="https://newrelic.com/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    https://newrelic.com/
                  </a>
                  , and its privacy practices here:
                  <a
                    href="https://newrelic.com/termsandconditions/privacy"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    https://newrelic.com/termsandconditions/privacy.
                  </a>
                </p>
              </li>
            </ul>

            <h4>Disabling cookies</h4>
            <p>
              You can typically remove or reject cookies via your browser
              settings. In order to do this, follow the instructions provided by
              your browser (usually located within the “settings,” “help”
              “tools” or “edit” menus). Many browsers are set to accept cookies
              until you change your settings.{" "}
            </p>
            <p>
              For further information about cookies, including how to see what
              cookies have been set on your computer or mobile device and how to
              manage and delete them, visit
              <a
                href=" www.allaboutcookies.org"
                target="_blank"
                rel="noreferrer"
              >
                www.allaboutcookies.org
              </a>
            </p>
            <p>
              If you do not accept our cookies, you may experience some
              inconvenience in your use of our Site. For example, we may not be
              able to recognize your computer or mobile device and you may need
              to log in every time you visit our Site.
            </p>

            <h4>Other technologies</h4>
            <p>
              In addition to cookies, our Sites may use other technologies, such
              as Flash technology, pixel tags, and software development kits (or
              SDKs) to collect information automatically.
            </p>

            <h4>Flash Technology</h4>
            <p>
              We may use Flash cookies (which are also known as Flash Local
              Shared Object (“LSOs”)) on our Site to collect and store
              information about your use of our Site. Unlike other cookies,
              Flash cookies cannot be removed or rejected via your browser
              settings. If you do not want Flash cookies stored on your computer
              or mobile device, you can adjust the settings of your Flash player
              to block Flash LSO storage using the tools contained in the
              Website Storage Settings Panel at this website. You can also
              control Flash LSOs by going to the Global Storage Settings Panel
              at this website and following the instructions. Please note that
              setting the Flash Player to restrict or limit acceptance of Flash
              LSOs may reduce or impede the functionality of some Flash
              applications, including, potentially, Flash applications used in
              connection with our Site.
            </p>

            <h4>Pixel tags</h4>
            <p>
              We may also use pixel tags (which are also known as web beacons
              and clear GIFs) on our Site and in our HTML formatted emails to
              track the actions of users on our Site and interactions with our
              emails. Unlike cookies, which are stored on the hard drive of your
              computer or mobile device by a website, pixel tags are embedded
              invisibly on webpages or within HTML formatted emails. Pixel tags
              are used to demonstrate that a webpage was accessed or that
              certain content was viewed, typically to measure the success of
              our marketing campaigns or engagement with our emails and to
              compile statistics about usage of the Site, so that we can manage
              our content more effectively.
            </p>

            <h4>Software Development Kits</h4>
            <p>
              Our mobile applications may use software development kits (“SDKs”)
              provided by third parties. SDKs enable us to provide features and
              functionality developed by third-party developers, including to
              provide us with analytics, social media integration, and
              advertising. The SDKs we use may enable third parties to collect
              information about the users of our mobile applications. The types
              of SDKs we use include:
            </p>

            <ul>
              <li>
                Analytics SDKs. These SDKs are used to collect information about
                use of our mobile applications. The information gathered may
                include the types of information described above in the section
                titled “Information automatically collected.” We use this
                information to help operate our mobile applications more
                efficiently, to gather broad demographic information, monitor
                the level of activity on and within the application, diagnose
                errors, and improve the mobile application.
              </li>
              <li>
                Social Media SDKs. These SDKs help you to interact with social
                networks you are signed into while using our mobile application,
                such as by sharing content with the social network and other
                features you use with the social network. Social networks may
                also work with our apps for analytics or advertising purposes,
                as discussed above.
              </li>

              <li>
                Advertising SDKs. These SDKs allow our advertising partners to
                collect information about your devices, and how you use our
                mobile application and other sites and applications over time;
                and to use this information to show you ads of potential
                interest and measure how the ads perform. These third parties’
                collection, use, and sharing of your personal information is
                subject to their own privacy policies. You may be able to
                control or limit use of certain information collected through
                advertising SDKs for purposes of targeted advertising. For
                additional information, please review the “Targeted online
                advertising” portion of the “Your Choices” section of this
                Privacy Policy.
              </li>
            </ul>
          </li>

          <li>
            <h2>Use of Your Personal Data</h2>
            <ul>
              <li>
                <p>To provide our Sites and Services.</p>
                <ul>
                  <li>
                    facilitate the creation of and secure your account on our
                    network;
                  </li>
                  <li>identify you as a User in the system;</li>
                  <li>
                    send you a welcome e-mail to verify ownership of the e-mail
                    address provided when your account was created;
                  </li>
                  <li>
                    respond to your inquiries related to employment
                    opportunities or other requests;
                  </li>
                  <li>provide the Service and customer support you request;</li>
                  <li>
                    resolve disputes, collect fees, and troubleshoot problems;
                  </li>
                  <li>
                    prevent, detect, and investigate potentially prohibited or
                    illegal activities, and enforce our User Agreement;
                  </li>
                  <li>
                    customize, measure and improve our Service and content;
                  </li>
                  <li>
                    tell you about our Service, service updates, and promotional
                    offers based on your communication preferences; ○ compare
                    information for accuracy, and verify it with third parties;
                    and
                  </li>
                  <li>
                    and other uses as described when we collect the information.
                  </li>
                </ul>
                <p>
                  To communicate with youIf you request information from us
                  (such as signing up for our newsletter), register on the
                  Sites, or participate in our contests or promotions, we may
                  send you Rudi-related marketing communications as permitted by
                  law. You will have the ability to opt out of such
                  communications.
                </p>

                <h4>
                  Use of Personal Data About Truck Owners on Behalf of Our
                  Shippers.
                </h4>
                <p>
                  We use personal information we collect about truck owners from
                  or on behalf of our shippers to provide services only as
                  directed or authorized by the shipper. We do not use this
                  information for our own purposes. Typically, our clients
                  direct or authorize us to use personal information collected
                  on their behalf to enable cargo matching and trip financing on
                  Rudi, to manage the delivery of goods, and to deliver
                  communications from the shippers to the truck owners and vice
                  versa.
                </p>

                <ul>
                  <li>
                    To comply with law.
                    <br />
                    We use your personal information as we believe necessary or
                    appropriate to comply with applicable laws, lawful requests
                    and legal process, such as to respond to subpoenas or
                    requests from government authorities. For compliance, fraud
                    prevention and safety We use your personal information as we
                    believe necessary or appropriate to (a) enforce the terms
                    and conditions that govern the Sites; (b) protect our
                    rights, privacy, safety or property, and/or that of you or
                    others; and (c) protect, investigate and deter against
                    fraudulent, harmful, unauthorized, unethical or illegal
                    activity.
                  </li>
                  <li>
                    With your consent.
                    <br />
                    We will request your consent to use your personal
                    information where required by law, such as where we would
                    like to send you certain marketing messages. If we request
                    your consent to use your personal information, you have the
                    right to withdraw your consent any time in the manner
                    indicated when we requested the consent or by contacting us.
                    If you have consented to receive marketing communications
                    from our third party partners, you may withdraw your consent
                    by contacting those partners directly.
                  </li>
                  <li>
                    To create anonymous data.
                    <br />
                    We may create Anonymous Data records from Personal Data by
                    excluding information that make the data personally
                    identifiable to you. We use this Anonymous Data to analyze
                    request and usage patterns so that we may enhance the
                    content of our Service and improve site navigation. We
                    reserve the right to use Anonymous Data for any purpose and
                    disclose Anonymous Data to third parties in our sole
                    discretion.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h2>Our Disclosure of Your Personal Data</h2>
            <p>
              We may disclose Personal Data to respond to legal requirements,
              enforce our policies, respond to claims that a listing or other
              content violates the rights of others, or protect anyone's rights,
              property, or safety. Such information will be disclosed in
              accordance with applicable laws and regulations.
            </p>
            <p>We may also share your Personal Data with:</p>

            <ul>
              <li>
                Service providers under contract who help with our business
                operations (such as fraud investigations, bill collection,
                affiliate and rewards programs).
              </li>
              <li>
                Our subsidiaries and corporate affiliates for purposes
                consistent with this Privacy Policy.
              </li>
              <li>
                Our shippers, when you are matched to their cargo using our
                Services.
              </li>
              <li>
                Other third parties to whom you explicitly ask us to send your
                information (or about whom you are otherwise explicitly notified
                and consent to when using a specific service).
              </li>
              <li>
                Professional advisors, such as lawyers, bankers, auditors and
                insurers, where necessary in the course of the professional
                services that they render to us.
              </li>
              <li>
                Law enforcement, governmental agencies, or authorized
                third-parties, in response to a verified request relating to a
                criminal investigation or alleged illegal activity or any other
                activity that may expose us, you, or any other Rudi User to
                legal liability. In such events, we will disclose information
                relevant to the investigation, such as name, city, state, ZIP
                code, telephone number, e-mail address, User ID history, IP
                address, fraud complaints, bidding and listing history, and
                anything else we may deem relevant to the investigation.
              </li>
              <li>
                Other business entities with which we perform a business
                transaction (or potential business transaction), such as a
                merger, consolidation, or acquisition, in which case we will
                make reasonable efforts to require that the recipient follow
                this Privacy Policy with respect to your Personal Data. In the
                event of an insolvency, bankruptcy or receivership, your
                Personal Data may also be transferred as a business asset.
              </li>
            </ul>
          </li>
          <li>
            <h2>Account Protection</h2>
            <p>
              Your password is the key to your account. Use unique numbers,
              letters and special characters, and do not disclose your Rudi
              password to anyone. If you do share your password or your Personal
              Data with others, remember that you are responsible for all
              actions taken in the name of your account. If you lose control of
              your password, you may lose substantial control over your Personal
              Data and may be subject to legally binding actions taken on your
              behalf. Therefore, if your password has been compromised for any
              reason, you should immediately notify Rudi and change your
              password.
            </p>
          </li>

          <li>
            <h2>Your Choices</h2>
            <h4>Accessing, Reviewing and Changing Your Personal Data</h4>
            <p>
              You may change any of your Personal Data in your account by
              editing your profile within your account or by sending an e-mail
              to us at the e-mail address set forth below. You may request
              deletion of your Personal Data by us, but please note that we may
              be required to keep this information and not delete it (or to keep
              this information for a certain time, in which case we will comply
              with your deletion request only after we have fulfilled such
              requirements). Upon your request, we will close your account and
              remove your Personal Data from view as soon as reasonably
              possible, based on your account activity and in accordance with
              applicable law. We do retain Personal Data from closed accounts to
              comply with law, prevent fraud, collect any fees owed, resolve
              disputes, troubleshoot problems, assist with any investigations,
              enforce our User Agreement, and take other actions otherwise
              permitted by law.
            </p>

            <h4>Marketing communications</h4>
            <p>
              You may opt out of marketing-related emails by clicking on a link
              at the bottom of our marketing emails, or by contacting us at{" "}
              <a href="mailto:support@ruditrip.com">support@ruditrip.com</a>.
              You may continue to receive service-related and other
              non-marketing emails.
            </p>

            <h4>Do Not Track Signals</h4>
            <p>
              Some Internet browsers may be configured to send "Do Not Track"
              signals to the online services that you visit. We currently do not
              currently respond to do not track signals. To find out more about
              "Do Not Track," please visit
              <a
                href="http://www.allaboutdnt.com."
                target="_blank"
                rel="noreferrer"
              >
                http://www.allaboutdnt.com.
              </a>
            </p>

            <h4>Choosing not to share your personal information</h4>
            <p>
              Where we are required by law to collect your personal information,
              or where we need your personal information in order to provide our
              services to you, if you do not provide this information when
              requested (or you later ask to delete it), we may not be able to
              provide you with our services. We will tell you what information
              you must provide to receive the services by designating it as
              required in or on the Sites or through other appropriate means.
            </p>
          </li>

          <li>
            <h2>Security</h2>
            <p>
              Your information is stored on our servers located in the United
              States. We use a variety of security technologies and procedures
              to help protect your Personal Data from unauthorized access, use
              or disclosure. However, as you probably know, third parties may
              unlawfully intercept or access transmissions or private
              communications, and other Users may abuse or misuse your Personal
              Data that they collect from the Site. Therefore, we do not
              promise, and you should not expect, that your Personal Data or
              private communications will always remain private.
            </p>
          </li>

          <li>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              We reserve the right to modify this Privacy Policy at any time. We
              encourage you to periodically review this page for the latest
              information on our privacy practices. If we make material changes
              to this Privacy Policy, we will notify you by updating the date of
              this Privacy Policy and posting it on the Sites and in the app
              stores where our mobile applications are available for download.
              We may (and, where required by law, will) also provide
              notification of changes in another way that we believe is
              reasonably likely to reach you, such as via e-mail (if you have an
              account where we have your contact information) or another manner
              through the Sites.
            </p>

            <p>
              Any modifications to this Privacy Policy will be effective upon
              our posting of the new terms and/or upon implementation of the new
              changes on the Sites (or as otherwise indicated at the time of
              posting). In all cases, your continued use of the Sites after the
              posting of any modified Privacy Policy indicates your acceptance
              of the terms of the modified Privacy Policy.
            </p>
          </li>

          <li>
            <h2>Contact Us</h2>
            <p>
              If you have any questions or concerns at all about our Privacy
              Policy, please feel free to email us at{" "}
              <a href="mailto:support@ruditrip.com">support@ruditrip.com</a>.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
