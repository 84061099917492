import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Modal, Input, Select, message } from "antd";
import postData from "../../hooks/useFetch";

import { useDispatch } from "react-redux";
import { menuAsync } from "../../features";

function CreateRole({ departments }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [param, setParam] = useState({
    department_id: null,
    role: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (param.department_id == null) message.error("Please choose department");
    else if (param.role.length < 3)
      message.warning("Enter a role name of atleast 3 characters");
    else
      postData({
        service: "create_role",
        data: param,
      }).then((data) => {
        if (data.success !== undefined && data.success === 1)
          message.success(data.message);
        dispatch(menuAsync.reload(true));
        setIsModalOpen(false);
      });
  };
  const handleCancel = () => {
    setParam({
      department_id: 1,
      role: "",
    });
    setIsModalOpen(false);
  };
  return (
    <div>
      <button
        onClick={showModal}
        className="flex items-center gap-3 px-6 py-2 text-white rounded-md success-bg"
      >
        <AiOutlinePlus /> <p className="text-sm">Add Role</p>
      </button>
      <Modal
        title="Create Role"
        open={isModalOpen}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <div>
          <p className="mb-2 text-sm">Department</p>
          <Select
            style={{
              width: "100%",
            }}
            required
            placeholder="Select department"
            onChange={(e) => {
              param.department_id = e;
              setParam({ ...param });
            }}
            options={departments.map((d) => {
              return { value: d.id.toString(), label: d.department };
            })}
          />
        </div>
        <div>
          <p className="mt-4 mb-2">Role Name</p>
          <Input
            type="text"
            value={param.role}
            placeholder="Enter the role name"
            onChange={(e) => {
              param.role = e.target.value;
              setParam({ ...param });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CreateRole;
