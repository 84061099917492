import React from "react";
import _ from "lodash";

import carbon_emission from "../../../assets/images/description/carbon_emission.jpg";
import financing from "../../../assets/images/description/fast-payment.webp";
import cargo from "../../../assets/images/description/cargo.jpg";
import data from "../../../assets/images/description/data.jpg";

function Description() {
  const items = [
    {
      image: financing,
      title: "Fast Payment",
      description: "Load with Rudi and you dot have to wait for payments",
    },
    {
      image: cargo,
      title: "Reducing the cost of cargo transportation",
      description:
        "We save cargo owners money by allowing the transporter to charge one way cost, automation, flexible capacity, service quality, and better visibility.",
    },
    {
      image: carbon_emission,
      title: "Eliminating Empty Miles - Reducing Carbon Emission",
      description:
        "Together we're creating a more sustainable and responsible supply chain and saving the planet while we do it.",
    },
    {
      image: data,
      title: "Increased Driver and truck Owner Income",
      description:
        "We're dedicated to helping carriers and drivers earn more with less hassle, keeping their trucks full on the lanes they like to run",
    },
  ];

  return (
    <div className="py-16 bg-gray-200">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="max-w-4xl px-8 sm:px-4">
            <p className="text-2xl font-extrabold text-center sm:text-3xl rudi-color">
              Rudi
              <br />
              Moving Africa's Cargo
            </p>
            <p className="my-4 text-base text-center md:text-lg">
              Rudi Corporation Limited is a logistics tech start-up, that serves
              shippers and carriers across the African continent by matching
              cargo to trucks in country and cross border, eliminating empty
              backhaul, process payments, and manage fleets. Rudi is a
              360-degree data driven solution that enhances trade through
              reduced cost of transport and Trip financing.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 px-2 mt-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xl:gap-6 sm:gap-4">
          {_.map(items, (item, i) => (
            <div
              key={i}
              className="relative flex items-center overflow-hidden cursor-pointer sm:rounded-lg group h-80"
            >
              <div
                style={{ backgroundImage: `url(${item.image})` }}
                className="absolute w-full h-full group flex items-center md:px-6 px-4 brightness-[70%] group-hover:brightness-[30%] transition transform duration-700 bg-no-repeat bg-cover bg-center"
              />
              <div className="z-10 flex items-center w-full h-full px-4 group md:px-6">
                <div>
                  <p className="text-2xl font-semibold text-white">
                    {item.title}
                  </p>
                  <p className="pt-3 overflow-hidden text-white transition-all duration-1000 transform max-h-0 group-hover:max-h-52">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Description;
