import React from "react";
import _ from "lodash";

import robert from "../../../assets/images/team/robert.png";
import davis from "../../../assets/images/team/davis.png";
import ivan from "../../../assets/images/team/ivan.png";
import mercy from "../../../assets/images/team/mercy.jpeg";
import { AiFillLinkedin } from "react-icons/ai";

function Team() {
  const team = [
    {
      image: robert,
      name: "Robert Okello",
      title: "Co-Founder and CEO",
      description:
        "15 years building Business. EX-Africa Regional manager Proto, EX-Head of Africa Razzo Africa, EX-Co-Founder Thinktank international",
      link: "https://www.linkedin.com/in/robert-okello-364b7625/",
    },
    {
      image: mercy,
      name: "Mercy (Nyamoita) Osiemo",
      title: "Operations Lead",
      description:
        "Business Developer, Growth specialist, Techprenuer, Sales Ops, Startups, Operations.",
      link: "https://www.linkedin.com/in/mercy-osiemo-680a6987/",
    },
    {
      image: davis,
      name: "Devis Musiu",
      title: "Co-founder and Head of Product",
      description:
        "Great at definition and alignment of Vision, strategy and Company Roadmaps",
      link: "https://www.linkedin.com/in/devis-musiu/",
    },
    {
      image: ivan,
      name: "Amoko Ivan",
      title: "Chief Technology Officer",
      description:
        "Driving innovation and optimizing technology stacks with a wealth of expertise in navigating complex technological challenges and delivering solutions that propel organizations toward sustained growth and success",
      link: "https://www.linkedin.com/in/amoko-ivan-36a069115/",
    },
  ];

  return (
    <div className="bg-gray-200">
      <div className="container py-16 mx-auto">
        <p className="text-3xl font-bold text-center rudi-color">Our Team</p>
        <div className="grid gap-6 px-4 mt-10 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
          {_.map(team, (member, i) => (
            <div className="bg-white rounded-lg cursor-pointer group" key={i}>
              <div className="flex justify-center w-full pt-8">
                <img
                  src={member.image}
                  alt={member.title + " - " + member.name}
                  className="w-32 h-32 rounded-full"
                />
              </div>
              <div className="px-6 pt-6">
                <p className="text-base font-bold text-center sm:line-clamp-1 group-hover:line-clamp-6">
                  {member.name}
                </p>
                <p className="my-2 text-sm text-center text-gray-700 sm:line-clamp-3 group-hover:line-clamp-6">
                  {member.description}
                </p>
                <p className="text-base font-bold text-center sm:line-clamp-1 group-hover:line-clamp-6">
                  {member.title}
                </p>
              </div>

              <div className="flex justify-center w-full pt-2 pb-6">
                <AiFillLinkedin className="text-3xl" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
