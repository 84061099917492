import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Modal, Input, message } from "antd";
import postData from "../../hooks/useFetch";

import { useSelector, useDispatch } from "react-redux";
import { user, menuAsync } from "../../features";

import {} from "react-redux";

function CreateDepartment() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = useSelector(user.profile);
  const dispatch = useDispatch();

  const [data, setData] = useState("");

  const showModal = () => {
    setData("");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    postData({
      service: "create_department",
      data: {
        team_id: profile.team,
        department: data,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        dispatch(menuAsync.reload(true));
        message.success(data.message);
        setIsModalOpen(false);
      } else message.error(data.message);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        onClick={showModal}
        className="flex items-center gap-3 px-6 py-2 text-white rounded-md success-bg"
      >
        <AiOutlinePlus /> <p className="text-sm">Create Department</p>
      </button>
      <Modal
        title="Create Team"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="mb-2">Department Name</p>
        <Input
          type="text"
          value={data}
          placeholder="Enter the department name"
          onChange={(e) => setData(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default CreateDepartment;
