import React, { useState } from "react";
import { BsUniversalAccessCircle } from "react-icons/bs";
import { Modal, message, Checkbox } from "antd";
import _ from "lodash";
import postData from "../../hooks/useFetch";

function RolePermission({ role }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);

  React.useEffect(() => {
    postData({
      service: "get_role_permission",
      data: {
        role_id: role.id,
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        setPermissions(data.data);
    });
  });

  //   const [param, setParam] = useState({
  //     department_id: null,
  //     role: "",
  //   });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    const ids = e.target.id.split("-");
    postData({
      service: "role_permission",
      data: {
        role_id: role.id,
        menu_id: ids[0],
        permission_id: ids[1],
      },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        message.success(data.message);
    });
  };

  return (
    <div>
      <button
        onClick={showModal}
        className="flex items-center gap-3 px-4 py-2 text-white rounded-md dark-blue-bg"
      >
        <BsUniversalAccessCircle className="text-lg" />{" "}
        <p className="text-sm">Permissions</p>
      </button>
      <Modal
        title={`${role.role} Permission Access`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div>
          {permissions.length > 0 &&
            permissions.map((permission, i) => (
              <div key={i} className="grid sm:grid-cols-2">
                <p className="capitalize">{permission.menu}</p>
                <div>
                  {Object.keys(permission.permissions).map((p, i) => (
                    <Checkbox
                      key={i}
                      id={`${permission.id}-${i + 1}`}
                      defaultChecked={permission.permissions[p] === 1}
                      onChange={onChange}
                    >
                      {_.startCase(p)}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
}

export default RolePermission;
