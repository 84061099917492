import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: "Dashboard",
  drawer: false,
  reload: false,
  loading: false,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setMenu, setDrawer, setReload, setLoading } = menuSlice.actions;

export const menuAsync = {
  menu: (menu) => (dispatch) => {
    dispatch(setMenu(menu));
  },
  drawer: (drawer) => (dispatch) => {
    dispatch(setDrawer(drawer));
  },
  reload: (reload) => (dispatch) => {
    dispatch(setReload(reload));
  },
  loading: (loading) => (dispatch) => {
    dispatch(setLoading(loading));
  },
};

export const menu = {
  menu: (state) => state.menu.menu,
  drawer: (state) => state.menu.drawer,
  reload: (state) => state.menu.reload,
  loading: (state) => state.menu.loading,
};

export default menuSlice.reducer;
