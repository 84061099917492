import React from "react";
import { BsBell } from "react-icons/bs";
import { Drawer } from "antd";
import postData from "../hooks/useFetch";
import n_icon from "../assets/images/no-notifications.png";
import _ from "lodash";
import { formatDateTime } from "../utils/utils";

// const { confirm } = Modal;

function NotificationCenter() {
  const icon_class = "text-2xl cursor-pointer hover:shadow-lg";
  const [notifications, setNotifications] = React.useState([]);

  // const navigate = useNavigate();
  // const logout = () => {
  //   confirm({
  //     title: "Are you sure, you want to logout?",
  //     icon: <ExclamationCircleFilled />,
  //     content: "When clicked the OK button, you will be logged out.",
  //     onOk() {
  //       return new Promise((resolve, reject) => {
  //         setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
  //         sessionStorage.clear();
  //         navigate("/");
  //         window.location.reload(false);
  //       }).catch(() => console.log("Oops errors!"));
  //     },
  //     onCancel() {
  //       navigate("/");
  //     },
  //   });
  // };

  // const items = [
  //   {
  //     key: "1",
  //     label: (
  //       <a href="/" target="_blank" rel="noreferrer" className="pe-20 ps-4">
  //         Profile
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         href="/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         onClick={logout}
  //         className="pe-20 ps-4"
  //       >
  //         Logout
  //       </a>
  //     ),
  //   },
  // ];

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (open)
      postData({
        service: "notifications",
        data: { offset: 0 },
      }).then((data) => {
        if (data.success !== undefined && data.success === 1)
          setNotifications(data.data);

        console.log(data.data);
      });
  }, [open]);

  return (
    <div className="p-3.5">
      {/* <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
        trigger={["click"]}
      >
        <BiUserCircle className={icon_class} />
      </Dropdown> */}
      <BsBell
        className={`${icon_class} cursor-pointer`}
        onClick={() => setOpen(true)}
      />

      <Drawer
        title="Notifications"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        {notifications.length === 0 ? (
          <div>
            <img
              src={n_icon}
              alt="No notifications"
              className="mx-auto max-h-28"
            />
            <p className="mt-4 text-center">No Notifications</p>
          </div>
        ) : (
          <div>
            {_.map(notifications, (n, i) => (
              <div key={i} className="p-2 border-b">
                <h6 className="font-semibold">{n.title}</h6>
                <p>{n.message}</p>
                <p className="mt-3 text-sm text-gray-700">
                  {formatDateTime(n.creation_datetime)}
                </p>
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default NotificationCenter;
