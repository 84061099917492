import React from "react";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

function SdgItem({ sdg }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <div
        onClick={handleOpen}
        className="overflow-hidden border rounded-lg cursor-pointer hover:shadow-md"
      >
        <div style={{ backgroundColor: `${sdg.color}` }} className="p-6">
          <img
            src={sdg.image}
            alt={sdg.title}
            className="object-contain w-full max-h-52"
          />
        </div>
        <div className="p-4">
          <p className="font-bold uppercase rudi-color">{sdg.title}</p>
          <p className="text-base line-clamp-3">{sdg.description}</p>
        </div>
      </div>
      <Dialog size={"sm"} open={open} handler={handleOpen}>
        <DialogHeader>
          <p className="uppercase">{sdg.title}</p>
        </DialogHeader>
        <DialogBody divider>
          <p className="text-lg">{sdg.description}</p>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default SdgItem;
