import { configureStore } from "@reduxjs/toolkit";

import menuReducer from "./features/menuSlice";
import userReducer from "./features/userSlice";

export default configureStore({
  reducer: {
    menu: menuReducer,
    user: userReducer,
  },
});
