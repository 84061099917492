export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export function formatDate(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month;

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year}`;
}

export function formatDateTime(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month;

  const hours = String(new Date(date).getHours()).padStart(2, "0");
  const minutes = String(new Date(date).getMinutes()).padStart(2, "0");
  const seconds = String(new Date(date).getSeconds()).padStart(2, "0");

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year} ${hours}:${minutes}:${seconds}`;
}

export const numberFormatter = (number) => {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
};
