import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import _ from "lodash";
import {
  AiOutlineDashboard,
  AiOutlineTeam,
  AiOutlineSetting,
  AiOutlineLogout,
} from "react-icons/ai";
import { MdPassword } from "react-icons/md";
import { BsMinecartLoaded, BsUniversalAccessCircle } from "react-icons/bs";
import { BiCalendar } from "react-icons/bi";
import { FcDepartment } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { menu, menuAsync, user } from "../../features";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Input, message, notification } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import postData from "../../hooks/useFetch";

const { confirm } = Modal;

function SideNav() {
  const profile = useSelector(user.profile);
  const [active, setActive] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const drawer = true;
  const drawer = useSelector(menu.drawer);

  const icon_class = `text-[20px] text-gray-200`;
  const group_menus = [
    [
      {
        icon: <AiOutlineDashboard className={icon_class} />,
        label: "Dashboard",
        link: "dashboard",
      },
      {
        icon: <BsMinecartLoaded className={icon_class} />,
        label: "Cargo",
        link: "cargo",
      },
      {
        icon: <BiCalendar className={icon_class} />,
        label: "Schedule",
        link: "schedule",
      },
      {
        icon: <AiOutlineTeam className={icon_class} />,
        label: "My Team",
        link: "my-team",
      },
      {
        icon: <FcDepartment className={icon_class} />,
        label: "Departments",
        link: "department",
      },
      {
        icon: <BsUniversalAccessCircle className={icon_class} />,
        label: "Roles",
        link: "roles",
      },
    ],
    [
      {
        icon: <AiOutlineSetting className={icon_class} />,
        label: "Settings",
        link: "settings",
      },
    ],
    [
      {
        icon: <AiOutlineLogout className={icon_class} />,
        label: "Logout",
        link: "logout",
      },
    ],
  ];

  /**
   * Those attatched to a team
   */
  const menus = [
    [
      {
        icon: <AiOutlineDashboard className={icon_class} />,
        label: "Dashboard",
        link: "dashboard",
      },
      {
        icon: <BsMinecartLoaded className={icon_class} />,
        label: "Cargo",
        link: "cargo",
      },
      {
        icon: <BiCalendar className={icon_class} />,
        label: "Schedule",
        link: "schedule",
      },
      {
        icon: <AiOutlineTeam className={icon_class} />,
        label: "My Team",
        link: "my-team",
      },
    ],
    [
      {
        icon: <AiOutlineSetting className={icon_class} />,
        label: "Settings",
        link: "settings",
      },
    ],
    [
      {
        icon: <MdPassword className={icon_class} />,
        label: "Change Password",
        link: "password",
      },
      {
        icon: <AiOutlineLogout className={icon_class} />,
        label: "Logout",
        link: "logout",
      },
    ],
  ];

  const logout = () => {
    confirm({
      title: "Are you sure, you want to logout?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, you will be logged out.",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          sessionStorage.clear();
          navigate("/");
          window.location.reload(false);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        navigate("/");
      },
    });
  };

  let location = useLocation();
  useEffect(() => {
    setActive(location.pathname.substring(1));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [password, setPassword] = useState({
    current_pwd: "",
    new_pwd: "",
  });

  const handleOk = () => {
    postData({
      service: "change_password",
      data: password,
    }).then((data) => {
      setIsModalOpen(false);
      if (data.success !== undefined && data.success === 1) {
        message.success(data.message);
        password.current_pwd = "";
        password.new_pwd = "";
        setPassword({ ...password });
      } else
        notification.open({
          message: "Change password",
          description: data.message,
          duration: 5,
        });
    });
  };

  return (
    <div className="h-full transition-all duration-500 transform main-bg">
      <Modal
        title="Change password"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          password.current_pwd = "";
          password.new_pwd = "";
          setPassword({ ...password });
          setIsModalOpen(false);
        }}
      >
        <br />
        <Input
          value={password.current_pwd}
          placeholder="Currenct password"
          type="password"
          onChange={(e) => {
            password.current_pwd = e.target.value;
            setPassword({ ...password });
          }}
        />
        <br />
        <br />
        <Input
          value={password.new_pwd}
          placeholder="New password"
          type="password"
          onChange={(e) => {
            password.new_pwd = e.target.value;
            setPassword({ ...password });
          }}
        />
      </Modal>
      <div className={`flex ${drawer && "justify-center"} items-center mt-2`}>
        <img
          src={logo}
          alt="logo"
          className={`m-1 w-10 ${!drawer && "ms-5 mt-2 w-10"}`}
        />
        {!drawer && <p className="text-xl font-bold text-gray-400">Rudi</p>}
      </div>
      <div className="flex flex-col justify-between h-[calc(100%_-_80px)] mt-4 overflow-y-auto">
        {_.map(profile.team_id !== null ? menus : group_menus, (menus, m) => (
          <div key={m}>
            {_.map(menus, (menu, i) => (
              <div key={i}>
                {(menu.link === "department" || menu.link === "roles") &&
                profile.team !== null ? (
                  <div>
                    {menu.link === "logout" ? (
                      <div
                        onClick={() => logout()}
                        className={`flex items-center cursor-pointer px-3 ${
                          drawer ? "justify-center" : "mx-3 gap-3"
                        } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                          active === menu.link && "rudi-bg"
                        }`}
                      >
                        {menu.icon}
                        <p
                          className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                            drawer ? "w-0" : "w-44"
                          }`}
                        >
                          {!drawer && menu.label}
                        </p>
                      </div>
                    ) : menu.link === "password" ? (
                      <div
                        onClick={() => setIsModalOpen(true)}
                        className={`flex items-center cursor-pointer px-3 ${
                          drawer ? "justify-center" : "mx-3 gap-3"
                        } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                          active === menu.link && "rudi-bg"
                        }`}
                      >
                        {menu.icon}
                        <p
                          className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                            drawer ? "w-0" : "w-44"
                          }`}
                        >
                          {!drawer && menu.label}
                        </p>
                      </div>
                    ) : (
                      <Link
                        key={i}
                        to={`/${menu.link}`}
                        onClick={() => dispatch(menuAsync.menu(menu.label))}
                      >
                        <div
                          className={`flex items-center px-3 ${
                            drawer ? "justify-center" : "mx-3 gap-3"
                          } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                            active === menu.link && "rudi-bg"
                          }`}
                        >
                          {menu.icon}
                          <p
                            className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                              drawer ? "w-0" : "w-44"
                            }`}
                          >
                            {!drawer && menu.label}
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : (
                  <div>
                    {menu.link === "logout" ? (
                      <div
                        onClick={() => logout()}
                        className={`flex items-center cursor-pointer px-3 ${
                          drawer ? "justify-center" : "mx-3 gap-3"
                        } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                          active === menu.link && "rudi-bg"
                        }`}
                      >
                        {menu.icon}
                        <p
                          className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                            drawer ? "w-0" : "w-44"
                          }`}
                        >
                          {!drawer && menu.label}
                        </p>
                      </div>
                    ) : menu.link === "password" ? (
                      <div
                        onClick={() => setIsModalOpen(true)}
                        className={`flex items-center cursor-pointer px-3 ${
                          drawer ? "justify-center" : "mx-3 gap-3"
                        } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                          active === menu.link && "rudi-bg"
                        }`}
                      >
                        {menu.icon}
                        <p
                          className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                            drawer ? "w-0" : "w-44"
                          }`}
                        >
                          {!drawer && menu.label}
                        </p>
                      </div>
                    ) : (
                      <Link
                        key={i}
                        to={`/${menu.link}`}
                        onClick={() => dispatch(menuAsync.menu(menu.label))}
                      >
                        <div
                          className={`flex items-center px-3 ${
                            drawer ? "justify-center" : "mx-3 gap-3"
                          } py-2.5 my-2 mx-3 transition-all duration-500 transform rounded-md hover:bg-gray-800 ${
                            active === menu.link && "rudi-bg"
                          }`}
                        >
                          {menu.icon}
                          <p
                            className={`text-sm line-clamp-1 text-gray-300 transition-all duration-500 transform ${
                              drawer ? "w-0" : "w-44"
                            }`}
                          >
                            {!drawer && menu.label}
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideNav;
