import React from "react";
import _ from "lodash";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

function Contacts() {
  const icon_class = "text-lg";
  const contacts = [
    {
      heading: "CARRIER SUPPORT",
      links: [
        {
          label: "support@ruditrip.com",
          link: "mailto:support@ruditrip.com",
          icon: <AiOutlineMail className={icon_class} />,
        },
        {
          label: "+254 724 281124",
          link: "tel:+254724281124",
          icon: <AiOutlinePhone className={icon_class} />,
        },
        {
          label: "+254 727 770622",
          link: "tel:+254727770622",
          icon: <AiOutlinePhone className={icon_class} />,
        },
      ],
    },
    {
      heading: "SHIPPER SUPPORT",
      links: [
        {
          label: "support@ruditrip.com",
          link: "mailto:support@ruditrip.com",
          icon: <AiOutlineMail className={icon_class} />,
        },
        {
          label: "+254 724 281124",
          link: "tel:+254724281124",
          icon: <AiOutlinePhone className={icon_class} />,
        },
        {
          label: "+254 727 770622",
          link: "tel:+254727770622",
          icon: <AiOutlinePhone className={icon_class} />,
        },
      ],
    },
  ];
  return (
    <div className="container grid grid-cols-1 py-16 mx-auto md:grid-cols-2">
      <div className="px-10 lg:px-20 md:px-10">
        <p className="text-xl font-bold rudi-color">
          Need immediate assistance?
        </p>
      </div>
      <div className="grid grid-cols-1 px-10 md:grid-cols-2 md:px-0">
        {_.map(contacts, (f, i) => (
          <div key={i}>
            <p className="mb-3 text-lg font-semibold capitalize mt-7 md:mt-0">
              {f.heading}
            </p>
            {_.map(f.links, (l, i) => (
              <a href={l.link} key={i}>
                <div className="flex items-center gap-2 mt-3 hover:text-orange-700">
                  {l.icon}
                  <p className="text-base">{l.label}</p>
                </div>
              </a>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contacts;
