/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import HeaderManage from "./header";
import ContentManage from "../content-manage";
import SideNav from "./side-nav";
import NotificationCenter from "../notification-center";
import postData from "../../hooks/useFetch";

import { useDispatch, useSelector } from "react-redux";
import { user, userAsync } from "../../features";
import Invitation from "../../pages/invitation";
import VerifyEmail from "../../pages/landing/welcome/verify-email";

function MainManage() {
  const dispatch = useDispatch();

  const profile = useSelector(user.profile);
  React.useEffect(() => {
    postData({
      service: "profile",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1)
        dispatch(userAsync.profile(data.data));
    });
  }, []);

  return (
    <div className="flex h-screen">
      {Object.keys(profile).length > 0 && profile.is_verify === 0 ? (
        <VerifyEmail />
      ) : (
        <>
          {Object.keys(profile).length > 0 && profile.invited === 1 ? (
            <Invitation />
          ) : (
            <>
              <SideNav />
              <div className="relative w-full h-full">
                <HeaderManage />
                <ContentManage />
              </div>
              <NotificationCenter />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MainManage;
